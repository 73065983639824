import { Fade, Spinner } from "reactstrap";

export default function LoadingHandlerScreen({ component, isLoading }) {

    return isLoading ?
        <div className="mt-5 w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner>
                Loading...
            </Spinner>
        </div>
        :
        <Fade in={open}>
            {component}
        </Fade>
};