import React, { Component } from 'react';
import { Table, Card, Badge, Button } from "reactstrap";

//Import Images
import { Link } from 'react-router-dom';
import hasImage from 'common/hasImage';
import { useAuth } from 'hooks/useAuth';
import isAllowed from 'components/Common/IsAllowed';
import NoRecords from 'components/Common/NoRecords';

const StatusCell = ({ status, name }) => {

    var status_color = 'danger';

    if (status == 1) {
        status_color = 'warning';
    } else if (status == 2) {
        status_color = 'info';
    } else if (status == 3) {
        status_color = 'success';
    };

    return (
        <Badge className={"rounded-pill bg-" + status_color}>{name}</Badge>
    );
};

function LatestOrders({ orders }) {

    const { userData: {
        orders_permissions: { manage_orders },
    } } = useAuth();

    return (
        <React.Fragment>
            <Card>
                <div className="card-body">
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                        <h4 className="card-title">5 últimos pedidos recebidos</h4>
                        <Link to={'/pedidos'}>
                            <Button color="primary" size="sm" className="waves-effect waves-light">
                                <i className="mdi mdi-package-variant"></i> Ver mais pedidos
                            </Button>
                        </Link>
                    </div>

                    <div className="table-responsive">

                        {
                            orders?.length > 0 ?
                                <Table className="align-middle table-centered table-vertical table-nowrap mb-1">
                                    <tbody>
                                        {
                                            orders.map((order, key) =>
                                                <tr key={key}>
                                                    <td>#{order.id_order}</td>
                                                    <td>
                                                        <img src={hasImage(order.image)} alt="user" className="avatar-xs me-2 rounded-circle" /> {order.buyer_name}
                                                    </td>
                                                    <td>
                                                        {order.company_name}
                                                    </td>
                                                    <td>
                                                        <StatusCell status={order.status_order} name={order.status_name} />
                                                    </td>
                                                    <td>
                                                        R${order.order_value}
                                                    </td>
                                                    <td>
                                                        {order.order_date}
                                                    </td>
                                                    <td>
                                                        <Link to={`/pedido-detalhes/${order.id_order}`}>
                                                            <Button color="primary" size="sm" className="waves-effect waves-light">
                                                                <i className="mdi mdi-magnify"></i>
                                                            </Button>
                                                        </Link>
                                                        {" "}
                                                        {
                                                            isAllowed(manage_orders) && (
                                                                <Link to={`/pedido-editar/${order.id_order}`}>
                                                                    <Button color="primary" size="sm" className="waves-effect waves-light">
                                                                        <i className="mdi mdi-pencil"></i>
                                                                    </Button>
                                                                </Link>
                                                            )
                                                        }

                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                                :
                                <NoRecords />
                        }

                    </div>
                </div>
            </Card>
        </React.Fragment>
    );
}

export default LatestOrders;