import PropTypes from 'prop-types';
import React from "react";
import { Route, Routes } from 'react-router-dom';

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import "./assets/scss/theme.scss";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import Authmiddleware from 'routes/middleware/Authmiddleware';
import { useAuth } from 'hooks/useAuth';

fakeBackend();

const App = () => {

  const { isLogged } = useAuth();

  return (
    <Routes>
      {
        userRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            exact={route.path}
            element={
              <Authmiddleware
                isLogged={isLogged}
                defaultComponent={
                  <route.default_component />
                }
                loggedComponent={
                  <route.logged_component />
                }
              />
            }
          />
        ))
      }
    </Routes>
  );
}


export default App;