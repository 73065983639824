import React, { useEffect } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Badge,
    Button
} from "reactstrap";
import { Link, useParams } from "react-router-dom";

import MetaTags from 'react-meta-tags';

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

//Import Images
import { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { get, post } from 'helpers/api_helper';
import { useAuth } from 'hooks/useAuth';
import hasImage from 'common/hasImage';
import LoadingHandlerScreen from 'components/Common/LoadingHandlerScreen';

const OrdersEdit = (props) => {

    const { id } = useParams();

    const [orderData, setOrderData] = useState({});
    const [companyData, setCompanyData] = useState({});

    const { userData: { id_admin } } = useAuth({});

    const [success_dlg, setsuccess_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [confirm_both, setconfirm_both] = useState(false);
    const [cancel_both, setcancel_both] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Pedidos", link: "/pedidos" },
        { title: "Atualizar Pedido", link: "#" },
    ];

    const fetchData = async () => {
        const response = await get(`order_details.php?id_order=${id}`);
        if (response.success) {
            setOrderData(response.order);
            setCompanyData(response.order.customer_data);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        props.setBreadcrumbItems('Atualizar Pedido', breadcrumbItems);
        fetchData();
    }, [id_admin]);

    const { delivery_address } = companyData;
    const { order_status } = orderData;

    //Print the Invoice
    const printInvoice = () => {
        window.print()
    };

    const updateStatus = (cancel) => {
        const update = async () => {

            if (!cancel) {
                if (parseInt(order_status) < 4) {

                    const payload = {
                        "id_admin_user": id_admin,
                        "id_order": id,
                        "status_order": parseInt(order_status) + 1
                    };

                    const response = await post("update_status_order.php", payload);

                    if (response.success) {
                        fetchData();
                    };
                }
            } else {
                const payload = {
                    "id_admin_user": id_admin,
                    "id_order": id,
                    "status_order": 4
                };

                const response = await post("update_status_order.php", payload);

                if (response.success) {
                    fetchData();
                };
            }


        };

        update();
    };

    const ActionButton = () => {

        const isDisabled = order_status >= 3;

        const buttonText = () => {

            if (order_status == 1) {
                return "Confirmar Pedido";

            } else if (order_status == 2) {
                return "Finalizar";

            } else {
                return "Pedido finalizado";
            }
        };

        const buttonColor = () => {
            if (order_status == 1) {
                return "success";

            } else if (order_status == 2) {
                return "warning";

            } else if (order_status == 3) {
                return "success";

            } else {
                return "danger";
            }
        };

        return (
            <Button disabled={isDisabled} onClick={() => setconfirm_both(true)} className={`btn btn-${buttonColor()} waves-effect waves-light`}>
                {buttonText()}
            </Button>
        );
    };

    const CancelButton = () => {

        if (order_status != 4 && order_status < 3) {
            return (
                <Button onClick={() => setcancel_both(true)} className="btn btn-danger  waves-effect waves-light">
                    <i className="mdi mdi-window-close"></i>
                </Button>
            );
        };

        return null;
    };

    const handleConfirmation = (isConfirmed, title, description) => {
        if (isConfirmed) {
            updateStatus();
        };

        setconfirm_both(false)
        setsuccess_dlg(true)
        setdynamic_title(title)
        setdynamic_description(description)
    };

    const handleCancel = (isConfirmed, title, description) => {
        if (isConfirmed) {
            updateStatus("cancel");
        };

        setcancel_both(false)
        setsuccess_dlg(true)
        setdynamic_title(title)
        setdynamic_description(description)
    };

    return <LoadingHandlerScreen isLoading={isLoading} component={
        <React.Fragment>
            <MetaTags>
                <title>Atualizar Pedido | Grupo Terra</title>
            </MetaTags>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs="12">
                                    <div className="invoice-title">
                                        <h4 className="float-end font-size-16"><strong>Pedido #{orderData?.order_number}</strong></h4>
                                        <h3 className="mt-0">
                                            <img src={hasImage(companyData?.company_logo)} alt="logo" height="100" />
                                        </h3>
                                    </div>
                                    <hr />
                                    <Row>
                                        <Col md="3">
                                            <address>
                                                <strong>Empresa:</strong><br />
                                                {companyData?.company_name}
                                            </address>
                                        </Col>
                                        <Col md="3">
                                            <address>
                                                <strong>Comprador:</strong><br />
                                                <img src={hasImage(companyData.user_image)} alt="user" className="avatar-xs me-2 rounded-circle" /> {companyData?.user_name}
                                            </address>
                                        </Col>
                                        <Col md="3">
                                            <address>
                                                <strong>Responsável:</strong><br />
                                                {companyData?.responsible_name + " (" + companyData?.responsible_function + ")"}
                                            </address>
                                        </Col>
                                        <Col md="3" className="text-end">
                                            <address>
                                                <strong>Endereço de Entrega:</strong><br />
                                                {delivery_address?.address}, {delivery_address?.number}<br />
                                                {delivery_address?.complement}<br />
                                                {delivery_address?.neighborhood}<br />
                                                {delivery_address?.city} - {delivery_address?.state}
                                            </address>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" className="mt-4">
                                            <address>
                                                <strong>Forma de Pagamento:</strong><br />
                                                <Badge className={"rounded-pill bg-success"}><strong>{orderData?.payment_method}</strong></Badge>
                                            </address>
                                        </Col>
                                        <Col xs="6" className="mt-4 text-end">
                                            <address>
                                                <strong>Data do Pedido:</strong><br />
                                                {orderData?.order_date}<br />
                                            </address>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12">
                                    <div>
                                        <div className="p-2">
                                            <h3 className="font-size-16"><strong>Lista de Produtos</strong></h3>
                                        </div>
                                        <div className="">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <td><strong>ID</strong></td>
                                                            <td>Imagem</td>
                                                            <td><strong>Nome</strong></td>
                                                            <td><strong>NCM</strong></td>
                                                            <td className="text-center"><strong>Preço</strong></td>
                                                            <td className="text-center"><strong>Quantidade</strong></td>
                                                            <td className="text-end"><strong>Total</strong></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            orderData?.products_data?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.product_id}</td>
                                                                    <td>
                                                                        <img alt={item.product_name} className="rounded avatar-sm card-img" src={hasImage(item?.image)} />
                                                                    </td>
                                                                    <td>{item.product_name}</td>
                                                                    <td>{item.ncm}</td>
                                                                    <td className="text-center">{item.product_price}</td>
                                                                    <td className="text-center">{item.product_quantity}</td>
                                                                    <td className="text-end">{item.product_total_price}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line text-center">
                                                                <strong>Subtotal</strong>
                                                            </td>
                                                            <td className="thick-line text-end">{orderData?.subtotal_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line text-center">
                                                                <strong>Cashback</strong>
                                                            </td>
                                                            <td className="no-line text-end">{orderData.discount_cashback}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line text-center">
                                                                <strong>Desconto Empresa</strong>
                                                            </td>
                                                            <td className="no-line text-end">{orderData.discount_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line text-center">
                                                                <strong>Frete</strong>
                                                            </td>
                                                            <td className="no-line text-end">{orderData.shipping_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line text-center">
                                                                <strong>Total</strong>
                                                            </td>
                                                            <td className="no-line text-end">
                                                                <h4 className="m-0">{orderData.total_price}</h4>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="d-print-none">
                                                <div className="float-end">
                                                    <Link to="#" onClick={printInvoice} className="btn btn-info waves-effect waves-light">
                                                        <i className="mdi mdi-file-pdf"> </i>
                                                    </Link>
                                                    {" "}
                                                    <CancelButton />
                                                    {" "}
                                                    <ActionButton />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {confirm_both ? (
                <SweetAlert
                    title="Você tem certeza que deseja atualizar este pedido?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => handleConfirmation(true, "Pedido atualizado!", "O cliente foi informado sobre a atualização.")}
                    onCancel={() => handleConfirmation(false, "Ação cancelada!", "Este pedido não sofreu alterações.")}
                >
                </SweetAlert>
            ) : null}
            {cancel_both ? (
                <SweetAlert
                    title="Você tem certeza que deseja cancelar este pedido?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => handleCancel(true, "Pedido cancelado!", "O cliente foi informado sobre a atualização.")
                    }
                    onCancel={() => handleCancel(false, "Ação cancelada!", "Este pedido não sofreu alterações.")
                    }
                >
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success
                    title={dynamic_title}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {dynamic_description}
                </SweetAlert>
            ) : null}
        </React.Fragment>

    } />
}

export default connect(null, { setBreadcrumbItems })(OrdersEdit);