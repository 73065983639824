import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"

import store from "./store"
import AuthContextProvider from "contexts/AuthContextProvider";
import { CompanyContextProvider } from "contexts/CompanyContextProvider"
import { Toaster } from "react-hot-toast"

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <CompanyContextProvider>
        <AuthContextProvider>
          <App />
          <Toaster
            position="bottom-center"
            reverseOrder={false}
          />
        </AuthContextProvider>
      </CompanyContextProvider>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
