import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button, Badge } from "reactstrap"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import "./datatables.scss"
import { Link } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { post } from "helpers/api_helper";
import hasImage from "common/hasImage";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";
import toast from "react-hot-toast";
import SweetAlert from "react-bootstrap-sweetalert";

const BuyersList = (props) => {

  const [cartList, setCartList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [removeUser, setRemoveUser] = useState(false);
  const [removeUserId, setRemoveUserId] = useState(null);

  const { userData: { id_admin } } = useAuth();

  const handleUserDelete = () => {

    const payload = {
      "id_user": removeUserId
    };

    post("user_buyer_delete.php", payload)
      .then(res => {
        if (res.success) {
          setRemoveUser(false);
          setRemoveUserId(null);
          getCompanyData();
        } else {
          setRemoveUser(false);
          setRemoveUserId(null);
          toast.error('Houve um erro ao remover esse usuário!')
        };
      })
      .catch(err => {
        toast.error('Houve um erro ao remover esse usuário!')
        console.error(err);
        setRemoveUser(false);
        setRemoveUserId(null);
      });
  };

  const ActionButton = ({ id }) => {
    return (
      <>
        <Link to={`/colaborador-detalhes/${id}`}>
          <Button color="primary" size="sm" className="waves-effect waves-light">
            <i className="mdi mdi-magnify"></i>
          </Button>
        </Link>
        {" "}
        <Link to={`/colaborador-editar/${id}`}>
          <Button color="primary" size="sm" className="waves-effect waves-light">
            <i className="mdi mdi-pencil"></i>
          </Button>
        </Link>
        {" "}
        <Button color="danger" size="sm" className="waves-effect waves-light" onClick={() => {
          setRemoveUser(true), setRemoveUserId(id);
        }}>
          <i className="mdi mdi-trash-can"></i>
        </Button>
      </>
    )
  };

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Compradores", link: "#" },
  ];

  useEffect(() => {

    props.setBreadcrumbItems('Compradores', breadcrumbItems);

    const fetchData = async () => {
      const payload = { id_admin_user: id_admin };
      const response = await post('user_buyer_list.php', payload);

      if (response.success) {
        const origin_users = response.users;
        const temp_users = origin_users.map(obj => {
          return {
            ...obj,
            acao: <ActionButton id={obj.id} />,
            image: <img alt={obj.buyer_name} className="avatar-xs me-2 rounded-circle" src={hasImage(obj.image)} />,
          }
        })
        setCartList(temp_users);
        setIsLoading(false);
      };
    };

    fetchData();
  }, [id_admin]);

  const data = {
    columns: [
      {
        label: "Imagem",
        field: "image",
        sort: "asc",
        width: 150,
      },
      {
        label: "Nome",
        field: "full_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Empresa",
        field: "company",
        sort: "asc",
        width: 270,
      },
      {
        label: "Cargo",
        field: "function",
        sort: "asc",
        width: 100,
      },
      {
        label: "Ação",
        field: "acao",
        width: 100,
        sort: "disabled"
      },
    ],
    rows: cartList,
  };

  return <LoadingHandlerScreen isLoading={isLoading} component={
    <React.Fragment>

      <MetaTags>
        <title>Compradores | Terra</title>
      </MetaTags>

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Pesquisar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive bordered data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {removeUser ? (
        <SweetAlert
          title="Você tem certeza que deseja remover esse usuário?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleUserDelete()
          }
          onCancel={() => setRemoveUser(false)
          }
        >
        </SweetAlert>
      ) : null}
    </React.Fragment>
  } />
}

export default connect(null, { setBreadcrumbItems })(BuyersList);