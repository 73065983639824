import { Card } from "reactstrap";

export default function NoRecords() {

    return (
        <Card className='primary p-2 d-flex justify-content-center align-items-center'>
            <i className='mdi mdi-table-search mdi-24px' />
            <p>
                Sem registros para exibir
            </p>
        </Card>
    )
}