import { AuthContext } from "contexts/AuthContextProvider";
import { useContext } from "react";

export const useAuth = () => {

    const {
        handleLogin,
        handleSignInUser,
        handleLogout,
        isLogged,
        userData
    } = useContext(AuthContext);

    return {
        handleLogin,
        handleSignInUser,
        handleLogout,
        isLogged,
        userData
    };

};