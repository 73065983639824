import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Input, InputGroup, Badge } from "reactstrap";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import { connect } from "react-redux";

// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import "./datatables.scss";
import { Link } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useAuth } from "hooks/useAuth";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";
import { get, post } from "helpers/api_helper";
import hasImage from "common/hasImage";
import isAllowed from "components/Common/IsAllowed";
import {Portuguese} from 'flatpickr/dist/l10n/pt';

const StatusCell = ({ status, name }) => {
  var status_color = 'danger';

  if (status == 1) {
    status_color = 'warning';
  } else if (status == 2) {
    status_color = 'info';
  } else if (status == 3) {
    status_color = 'success';
  };

  return (
    <Badge className={"rounded-pill bg-" + status_color}>{name}</Badge>
  );
};

const SellerSalesReport = (props) => {
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Relatório de Vendas por Vendedor", link: "#" },
  ];

  const { userData: { id_admin, orders_permissions: { manage_orders }, } } = useAuth();

  const [orderList, setOrderList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [formData, setFormData] = useState({
    empresa: "",
    comprador: "",
    status: null,
    dataInicio: "",
    dataFim: ""
  });
  const [isLoading, setIsLoading] = useState(true);

  const adjustDate = (date) => {
    return date ? date.toISOString().split('T')[0] : ""; // Verificar se a data é nula antes de formatá-la
  };

  const getSellerList = async () => {
    const payload = { id_admin_user: id_admin };
    const response = await post('user_list.php', payload);

    if (response.success) {
      const origin_users = response.users;

      const temp_users = origin_users.map(obj => {
        return {
          value: obj.id,
          label: obj.full_name
        };
      });

      setSellerList(temp_users);
    };
  };

  useEffect(() => {
    props.setBreadcrumbItems('Relatório de Vendas por Vendedor', breadcrumbItems);

    const fetchData = async () => {
      setIsLoading(true);
      const payload = { id_admin_user: id_admin };
      const response = await post('seller_orders_list.php', payload);

      if (response.success) {
        const origin_orders = response.orders;

        const temp_orders = origin_orders.map(obj => {
          return {
            ...obj,
            acao: <ActionButton id={obj.id_order} />,
            status: <StatusCell status={obj.status_order} name={obj.status_name} />,
            image: <img alt={obj.name} className="avatar-xs me-2 rounded-circle" src={hasImage(obj.image)} />,
          }
        });
 
        setOrderList(temp_orders);
        setIsLoading(false);
      };
    };

    fetchData();
    getSellerList();
  }, []);

  const ActionButton = ({ id }) => {
    return (
      <>
        <Link to={`/pedido-detalhes/${id}`}>
          <Button color="primary" size="sm" className="waves-effect waves-light">
            <i className="mdi mdi-magnify"></i>
          </Button>
        </Link>
        {" "}
        {isAllowed(manage_orders) && (
          <Link to={`/pedido-editar/${id}`}>
            <Button color="primary" size="sm" className="waves-effect waves-light">
              <i className="mdi mdi-pencil"></i>
            </Button>
          </Link>
        )}
      </>
    );
  };

  const handleFilter = async () => {

    setIsLoading(true);

    const payload = {
      id_admin_user: id_admin,
      seller_id: formData?.seller ? formData?.seller?.value : "",
      status: formData?.status?.value? formData?.status?.value : '',
      data_inicio: adjustDate(formData.dataInicio && formData.dataInicio[0]),
      data_fim: adjustDate(formData.dataFim && formData.dataFim[0])
    };

    const response = await post('seller_orders_list.php', payload);

    if (response.success) 
    {
      const origin_orders = response.orders;

      const temp_orders = origin_orders.map(obj => {
        return {
          ...obj,
          acao: <ActionButton id={obj.id_order} />,
          status: <StatusCell status={obj.status_order} name={obj.status_name} />,
          image: <img alt={obj.name} className="avatar-xs me-2 rounded-circle" src={hasImage(obj.image)} />,
        }
      });

      setOrderList(temp_orders);
      setIsLoading(false);
    };
  };

  const getPDFUrl = () => {

    const baseUrl = "https://matrixterra.com.br/ws/admin/relatorios/Relatorio-PDF/pdf-relatorio-sellers-orders.php";
    const queryParams = new URLSearchParams({
      id_admin_user: id_admin,
      seller_id: formData.seller ? formData.seller.value : "",
      status: formData?.status ? formData.status.value : '',
      data_inicio: adjustDate(formData.dataInicio && formData.dataInicio[0]),
      data_fim: adjustDate(formData.dataFim && formData.dataFim[0])
    }).toString();

    const url = `${baseUrl}?${queryParams}`;

    return url;
  };

  const data = {
    columns: [
      {
        label: "Imagem",
        field: "image",
        sort: "asc",
        width: 150,
      },
      {
        label: "Comprador",
        field: "buyer_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Valor",
        field: "order_value",
        sort: "asc",
        width: 100,
      },
      {
        label: "Data de Compra",
        field: "order_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ação",
        field: "acao",
        width: 100,
        sort: "disabled"
      },
    ],
    rows: orderList,
  };

  const optionGroup2 = [
    {
      label: "Status de Pedido",
      options: [
        { label: "Aguardando", value: "1" },
        { label: "Confirmado", value: "2" },
        { label: "Finalizado", value: "3" },
        { label: "Cancelado", value: "4" },
      ]
    },
  ];

  const optionGroup3 = [
    {
      label: "Vendedores Terra",
      options: sellerList
    },
  ];

  const animatedComponents = makeAnimated();

  return <LoadingHandlerScreen isLoading={isLoading} component={
    <React.Fragment>

      <MetaTags>
        <title>Relatório de Vendas por Vendedor | Terra</title>
      </MetaTags>
      <Row>
        <Card>
          <CardBody>
            <h4 className="card-title">Filtros de Relatório</h4>
            <AvForm className="needs-validation">
              <Row>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom03">Vendedor</Label>
                    <Select
                      options={optionGroup3}
                      classNamePrefix="select2-selection"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      placeholder="Selecione um vendedor terra"
                      value={formData.seller}
                      onChange={selectedOption => setFormData({ ...formData, seller: selectedOption })}
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom03">Status</Label>
                    <Select
                      options={optionGroup2}
                      classNamePrefix="select2-selection"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      placeholder="Selecione um status de pedido"
                      value={formData.status}
                      onChange={selectedOption => setFormData({ ...formData, status: selectedOption })}
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">Data início</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd/mm/aaaa"
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          locale: Portuguese
                        }}
                        value={formData.dataInicio}
                        onChange={date => setFormData({ ...formData, dataInicio: date })}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">Data Fim</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd/mm/aaaa"
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          locale: Portuguese
                        }}
                        value={formData.dataFim}
                        onChange={date => setFormData({ ...formData, dataFim: date })}
                      />
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              <Button color="primary" type="submit" onClick={handleFilter}>
                <i className="mdi mdi-filter"></i> Aplicar filtros
              </Button>
              {" "}
              <a download href={getPDFUrl()} className="btn btn-info waves-effect waves-light">
                <i className="mdi mdi-file-pdf"></i> Baixar PDF
              </a>
            </AvForm>
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Pesquisar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive bordered data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  } />
}

export default connect(null, { setBreadcrumbItems })(SellerSalesReport);
