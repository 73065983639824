import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, Button, Label, Input, InputGroup, Badge } from "reactstrap"
import makeAnimated from "react-select/animated";
import Select from "react-select"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import "./datatables.scss"
import { Link } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useAuth } from "hooks/useAuth";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";
import { get, post } from "helpers/api_helper";
import hasImage from "common/hasImage";
import {Portuguese} from 'flatpickr/dist/l10n/pt';

const ShoppingCartReport = (props) => {

  const { userData: { id_admin } } = useAuth();

  const adjustDate = (date) => {
    return date ? date.toISOString().split('T')[0] : ""; // Verificar se a data é nula antes de formatá-la
  };

  const [cartList, setCartList] = useState([]);
  const [formData, setFormData] = useState({
    empresa: "",
    comprador: "",
    valorMinimo: "",
    valorMaximo: "",
    dataInicio: "",
    dataFim: ""
  });
  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Relatório de Carrinhos", link: "#" },
  ];

  useEffect(() => {
    props.setBreadcrumbItems('Relatório de Carrinhos', breadcrumbItems);

    const fetchData = async () => {
      const payload = { id_admin_user: id_admin };
      const response = await post('cart_list.php', payload);

      if (response.success) {
        const origin_cart = response.cart;
        const temp_cart = origin_cart.map(obj => {
          return {
            ...obj,
            acao: <ActionButton id={obj.id_cart} />,
            image: <img alt={obj.buyer_name} className="avatar-xs me-2 rounded-circle" src={hasImage(obj.image)} />,
            selected_products: <Badge className='bg-success'>{obj.selected_products}</Badge>,
            removed_products: <Badge className='bg-danger'>{obj.removed_products}</Badge>
          }
        });

        setCartList(temp_cart);
      };
    };

    const getAllCustomers = async () => {
      const payload = { id_admin_user: id_admin };
      const response = await get('list_companies/list_all_holdings.php', payload);

      if (response.success) {
        setCustomers([{
          label: "Lista de Clientes Terra",
          options: response.holdings.map(obj => {
            return { label: obj.fantasy_name, value: obj.id }
          })
        }]);
      };
    };

    fetchData();
    getAllCustomers();
    setIsLoading(false);
  }, []);

  const ActionButton = ({ id }) => {
    return (
      <Link to={`/carrinhos-detalhes/${id}`}>
        <Button color="primary" size="sm" className="waves-effect waves-light">
          <i className="mdi mdi-magnify"></i>
        </Button>
      </Link>
    )
  };

  const handleFilter = async () => {

    const payload = {
      id_admin_user: id_admin,
      nome_comprador: formData.comprador ? formData.comprador.label : "",
      nome_empresa: formData.empresa ? formData.empresa.label : "",
      valor_minimo: formData.valorMinimo,
      valor_maximo: formData.valorMaximo,
      data_inicio: adjustDate(formData.dataInicio && formData.dataInicio[0]),
      data_fim: adjustDate(formData.dataFim && formData.dataFim[0])
    };

    const response = await post('relatorios/relatorio-cart.php', payload);

    if (response.success) {
      const origin_orders = response.cart;

      const temp_orders = origin_orders.map(obj => {
        return {
          ...obj,
          acao: <ActionButton id={obj.id_cart} />,
          image: <img alt={obj.buyer_name} className="avatar-xs me-2 rounded-circle" src={hasImage(obj.image)} />,
          selected_products: <Badge className='bg-success'>{obj.selected_products}</Badge>,
          removed_products: <Badge className='bg-danger'>{obj.removed_products}</Badge>
        }
      });

      console.log(temp_orders)

      setCartList(temp_orders);
      setIsLoading(false);
    };
  };

  const getPDFUrl = () => {

    const baseUrl = "https://matrixterra.com.br/ws/admin/relatorios/Relatorio-PDF/pdf-relatorio-cart.php";
    const queryParams = new URLSearchParams({
      id_admin_user: id_admin,
      nome_comprador: formData.comprador ? formData.comprador.label : "",
      nome_empresa: formData.empresa ? formData.empresa.label : "",
      valor_minimo: formData.valorMinimo,
      valor_maximo: formData.valorMaximo,
      data_inicio: adjustDate(formData.dataInicio && formData.dataInicio[0]),
      data_fim: adjustDate(formData.dataFim && formData.dataFim[0])

    }).toString();

    const url = `${baseUrl}?${queryParams}`;

    return url;
  };

  const data = {
    columns: [
      {
        label: "Imagem",
        field: "image",
        sort: "asc",
        width: 150,
      },
      {
        label: "Comprador",
        field: "buyer_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Empresa",
        field: "company_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Valor Atual",
        field: "cart_value",
        sort: "asc",
        width: 100,
      },
      {
        label: "Produtos Selecionados",
        field: "selected_products",
        sort: "asc",
        width: 100,
      },
      {
        label: "Produtos Removidos",
        field: "removed_products",
        sort: "asc",
        width: 100,
      },
      {
        label: "Data de Abertura",
        field: "cart_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ação",
        field: "acao",
        width: 100,
        sort: "disabled"
      },
    ],
    rows: cartList
  };

  return <LoadingHandlerScreen isLoading={isLoading} component={
    <React.Fragment>

      <MetaTags>
        <title>Relatório de Carrinhos | Terra</title>
      </MetaTags>
      <Row>
        <Card>
          <CardBody>
            <h4 className="card-title">Filtros de Relatório</h4>
            <AvForm className="needs-validation">
              <Row>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom04">Valor Mínimo</Label>
                    <AvField
                      name="valorMinimo"
                      placeholder="Insira o valor mínimo"
                      type="number"
                      className="form-control"
                      id="validationCustom02"
                      value={formData.valorMinimo}
                      onChange={e => setFormData({ ...formData, valorMinimo: e.target.value })}
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom04">Valor Máximo</Label>
                    <AvField
                      name="valorMaximo"
                      placeholder="Insira o valor máximo"
                      type="number"
                      className="form-control"
                      id="validationCustom02"
                      value={formData.valorMaximo}
                      onChange={e => setFormData({ ...formData, valorMaximo: e.target.value })}
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">Data início</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd/mm/aaaa"
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          locale: Portuguese
                        }}
                        value={formData.dataInicio}
                        onChange={date => setFormData({ ...formData, dataInicio: date })}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">Data Fim</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd/mm/aaaa"
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          locale: Portuguese
                        }}
                        value={formData.dataFim}
                        onChange={date => setFormData({ ...formData, dataFim: date })}
                      />
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              <Button color="primary" type="submit" onClick={handleFilter}>
                <i className="mdi mdi-filter"></i> Aplicar filtros
              </Button>
              {" "}
              <a download href={getPDFUrl()} className="btn btn-info waves-effect waves-light">
                <i className="mdi mdi-file-pdf"></i> Baixar PDF
              </a>
            </AvForm>
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Pesquisar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive bordered data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  } />
}

export default connect(null, { setBreadcrumbItems })(ShoppingCartReport);