import React from "react"
import PropTypes from 'prop-types'
import { Button, Col, Label, Row } from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { connect } from "react-redux"
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
  changeTopbarTheme,
  showRightSidebarAction,
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import "../../components/CommonForBoth/rightbar.scss";
import { MDBDataTable } from "mdbreact";

const ActionButton = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button color="primary" size="sm" className="waves-effect waves-light">
        <i className="mdi mdi-magnify"></i>
      </Button>
      <Button color="success" size="sm" className="waves-effect waves-light">
        <i className="mdi mdi-cart-plus"></i>
      </Button>
      <Button color="danger" size="sm" className="waves-effect waves-light">
        <i className="mdi mdi-trash-can-outline"></i>
      </Button>
    </div>
  )
};

const data = {
  columns: [
    {
      label: "ID",
      field: "id",
      sort: "asc",
      width: 100,
    },
    {
      label: "Nome",
      field: "nome",
      sort: "asc",
      width: 300,
    },
    {
      label: "Qntd",
      field: "quantidade",
      sort: "asc",
      width: 150,
    },
    {
      label: "P. Unit.",
      field: "precoUnitario",
      sort: "asc",
      width: 100,
    },
    {
      label: "Ação",
      field: "acao",
      sort: "asc",
      width: 100,
    },
  ],
  rows: Array(10).fill().map((_, index) => ({
    id: "ABC" + index,
    nome: <strong className="text-nowrap">Chave de Fenda Phillips</strong>,
    quantidade: 2 * index,
    precoUnitario: "R$19.99",
    acao: <ActionButton />,
  }))
};

const optionGroup2 = [
  {
    label: "Usuários Compradores",
    options: [
      { label: "Rafael Camargo (Ultra Solucoes)", value: "rafael" },
      { label: "Rafael Camargo (Ultra Solucoes)", value: "bruna" },
      { label: "Rafael Camargo (Ultra Solucoes)", value: "rodrigo" },
      { label: "Rafael Camargo (Ultra Solucoes)", value: "roberto" },
    ]
  },
];

const animatedComponents = makeAnimated();

const RightSidebar = props => {
  return (
    <React.Fragment>
      <div className="right-bar">
        <SimpleBar style={{ height: "100vh" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  props.showRightSidebarAction(false)
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Carrinho Personalizado</h5>
            </div>

            <hr className="my-0" />

            <div className="p-4">
              <Row>
                <div className="mb-3">
                  <Label htmlFor="validationCustom03">Usuário</Label>
                  <Select
                    isMulti={true}
                    options={optionGroup2}
                    classNamePrefix="select2-selection"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    placeholder="Selecione um usuário"
                  />
                </div>
              </Row>

              <hr className="mt-1" />

              <Row className="col-12">
                <MDBDataTable responsive bordered data={data} />
              </Row>
              <Button color="success" size="lg" className="waves-effect waves-light">
                <i className="mdi mdi-cart ms-1" /> Encaminhar pedido
              </Button>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </React.Fragment>
  )
}

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changePreloader: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  isPreloader: PropTypes.any,
  layoutType: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  topbarTheme: PropTypes.any
}

const mapStateToProps = state => {
  return { ...state.Layout }
}

export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
  showRightSidebarAction,
})(RightSidebar)
