import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';


import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Button,
} from "reactstrap"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import ReactInputMask from "react-input-mask";
import { MDBDataTable } from "mdbreact";

import "../Tables/datatables.scss";
import { Link } from "react-router-dom";

const CreateCustomerBranch = (props) => {

  const avatar3 = "https://img.freepik.com/vetores-gratis/modelo-de-logotipo-de-amigos_23-2149505594.jpg?w=740&t=st=1699566183~exp=1699566783~hmac=ca55f1ed768d5e5cda5a34de30e543d10a224d8567efa92d264f1010ffa62218";

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Clientes", link: "/clientes" },
    { title: "Incluir Unidade", link: "#" },
  ];

  const ActionButton = () => {
    return (
      <Link to={`/pedidos-detalhes/164161`}>
        <Button color="primary" size="sm" className="waves-effect waves-light">
          <i className="mdi mdi-magnify"></i> Detalhes
        </Button>
      </Link>
    )
  };

  const CreateBranchButton = () => (
    <Link to="/criar-usuario-terra">
      <Button size='lg' className="btn btn-success waves-effect waves-light"><i className='mdi mdi-account-plus'> </i>Adicionar Unidade</Button>
    </Link>
  );

  const CreateEmployeeButton = () => (
    <Link to="/cadastrar-funcionario-cliente/">
      <Button size='lg' className="btn btn-success waves-effect waves-light"><i className='mdi mdi-account-plus'> </i>Adicionar colaborador</Button>
    </Link>
  );

  const branchData = {
    columns: [
      {
        label: "Número",
        field: "number",
        sort: "asc",
        width: 50,
      },
      {
        label: "Nome",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Número de Colaboradores",
        field: "employees",
        sort: "asc",
        width: 150,
      },
      {
        label: "Data de Inclusão",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Comprado",
        field: "total",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ações",
        field: "action",
        width: 150,
      },
    ],
    rows: Array(15).fill().map((_, index) => ({
      number: index,
      name: "Filial " + index,
      employees: 2 * index,
      date: index + 1 + "/11/2023",
      total: "R$" + index * 1000 * 1.25,
      action: <ActionButton />,
    }))
  };

  const employeesData = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Whatsapp",
        field: "whatsapp",
        sort: "asc",
        width: 200,
      },
      {
        label: "Position",
        field: "position",
        sort: "asc",
        width: 270,
      },
      {
        label: "Pedidos Realizados",
        field: "pedidosRealizados",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Comprado",
        field: "totalComprado",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ações",
        field: "action",
        width: 150,
      },
    ],
    rows: Array(15).fill().map((_, index) => ({
      name: "Fulano Menezes de Souza " + index,
      whatsapp: "51 9 9999-888" + index,
      position: "Vendedor Master",
      pedidosRealizados: index * 3,
      totalComprado: "R$" + 1000 * index * 1.75,
      action: <ActionButton />,
    })),
  };

  useEffect(() => {
    props.setBreadcrumbItems('Incluir Unidade', breadcrumbItems)
  });

  return (
    <React.Fragment>

      <MetaTags>
        <title>Incluir Unidade | Grupo Terra</title>
      </MetaTags>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} sm={6} md={4} lg={3} className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-thumbnail"
                    alt="Imagem"
                    width="200"
                    src={avatar3}
                  />
                </Col>
                <Col xs={12} sm={6} md={8} lg={9}>
                  <div className="mt-5 mt-lg-4">
                    <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informações Principais</h5>

                    <form>
                      <div className="row mb-4">
                        <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Nome Fantasia</label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control" id="horizontal-firstname-input" />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Razão Social</label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control" id="horizontal-firstname-input" />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label htmlFor="horizontal-email-input" className="col-sm-3 col-form-label">CNPJ</label>
                        <div className="col-sm-9">
                          <input type="number" className="form-control" id="horizontal-email-input" />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">Endereço</label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control" id="horizontal-email-input" />
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row className="mb-3">
                <label
                  htmlFor="senha"
                  className="col-md-2 col-form-label"
                >
                  Modalidade de Unidade
                </label>
                <div className="col-md-10">
                  <select className="form-control" id="funcao">
                    <option>Selecione uma modalidade *</option>
                    <option>Holding (Principal)</option>
                    <option>Matriz</option>
                    <option>Filial</option>
                  </select>
                </div>
              </Row>
              <Row className="mb-3">
                <label
                  htmlFor="senha"
                  className="col-md-2 col-form-label"
                >
                  Telefone
                </label>
                <div className="col-md-10">
                  <input
                    id="senha"
                    className="form-control"
                    type="number"
                    placeholder="Insira o telefone da empresa *"
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label
                  htmlFor="senha"
                  className="col-md-2 col-form-label"
                >
                  Endereço de Entrega
                </label>
                <div className="col-md-10">
                  <input
                    id="senha"
                    className="form-control"
                    type="number"
                    placeholder="Insira o telefone da empresa *"
                  />
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col >
          <Card>
            <CardBody>
              <CardTitle className="h4">Filiais desta Unidade</CardTitle>
              <CreateBranchButton />
              <div className="mt-3">
                <MDBDataTable responsive striped bordered data={branchData} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col >
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-2">Colaboradores desta Unidade</CardTitle>
              <CreateEmployeeButton />
              <div className="mt-3">
                <MDBDataTable responsive striped bordered data={employeesData} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(CreateCustomerBranch);