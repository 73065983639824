import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import classNames from "classnames"

// Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import { useAuth } from "hooks/useAuth"

const SidebarContent = () => {
  const ref = useRef()
  const location = useLocation()

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isAllowed = (permission) => {
    if (permission === '1') return true;
    return false;
  };

  const { userData } = useAuth();

  useEffect(() => {
    if (ref.current) {
      ref.current.recalculate()
    }
  }, [ref]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const menuItems = [
    { to: "/dashboard", icon: "mdi mdi-view-dashboard", label: "Dashboard", permission: "1" },
    { to: "/pedidos", icon: "mdi mdi-package-variant", label: "Pedidos", permission: userData.orders_permissions.visualize_orders },
    { to: "/produtos", icon: "mdi mdi-hammer-wrench", label: "Produtos", permission: userData.products_permissions.visualize_products },
    { to: "/carrinhos", icon: "mdi mdi-cart-variant", label: "Carrinhos", permission: userData.orders_permissions.visualize_orders },
    { to: "/usuarios-terra", icon: "mdi mdi-account", label: "Usuários Terra", permission: userData.terra_user_permissions.visualize_users },
    { to: "/clientes", icon: "mdi mdi-factory", label: "Clientes", permission: userData.customers_permissions.visualize_customers },
    { to: "/compradores", icon: "mdi mdi-account-hard-hat", label: "Compradores", permission: userData.customers_permissions.visualize_customers },
    {
      label: "Relatórios",
      icon: "mdi mdi-file-table",
      permission: userData.statistics_permissions.visualize_statistics,
      submenu: [
        { to: "/relatorios-de-pedidos", label: "Relatório de Pedidos" },
        { to: "/relatorios-de-carrinhos", label: "Relatório de Carrinhos" },
        { to: "/relatorios-de-vendas-vendedor", label: "Relatório de Vendas por Vendedor" }
      ]
    },
    // { path: "/email-inbox", name: "Inbox", allowed: false },
    // { path: "/email-read", name: "Email Read", allowed: false },
    // { path: "/email-compose", name: "Email Compose", allowed: false },
    // { path: "/calendar", name: "Calendar", allowed: false },
    // { path: "/form-elements", name: "Form Elements", allowed: false },
    // { path: "/form-validation", name: "Form Validation", allowed: false },
    // { path: "/form-advanced", name: "Form Advanced", allowed: false },
    // { path: "/form-editors", name: "Form Editors", allowed: false },
    // { path: "/form-uploads", name: "Form File Upload", allowed: false },
    // { path: "/form-xeditable", name: "Form Xeditable", allowed: false },
    // { path: "/ui-alerts", name: "Alerts", allowed: false },
    // { path: "/ui-buttons", name: "Buttons", allowed: false },
    // { path: "/ui-badge", name: "Badge", allowed: false },
    // { path: "/ui-cards", name: "Cards", allowed: false },
    // { path: "/ui-carousel", name: "Carousel", allowed: false },
    // { path: "/ui-dropdowns", name: "Dropdowns", allowed: false },
    // { path: "/ui-grid", name: "Grid", allowed: false },
    // { path: "/ui-images", name: "Images", allowed: false },
    // { path: "/ui-lightbox", name: "Lightbox", allowed: false },
    // { path: "/ui-modals", name: "Modals", allowed: false },
    // { path: "/ui-pagination", name: "Pagination", allowed: false },
    // { path: "/ui-popover-tooltip", name: "Popover & Tooltips", allowed: false },
    // { path: "/ui-rangeslider", name: "Range Slider", allowed: false },
    // { path: "/ui-session-timeout", name: "Session Timeout", allowed: false },
    // { path: "/ui-progressbars", name: "Progress Bars", allowed: false },
    // { path: "/ui-sweet-alert", name: "Sweet-Alert", allowed: false },
    // { path: "/ui-tabs-accordions", name: "Tabs & Accordions", allowed: false },
    // { path: "/ui-typography", name: "Typography", allowed: false },
    // { path: "/ui-video", name: "Video", allowed: false },
    // { path: "/apex-charts", name: "Apex charts", allowed: false },
    // { path: "/charts-chartist", name: "Chartist Chart", allowed: false },
    // { path: "/charts-chartjs", name: "Chartjs Chart", allowed: false },
    // { path: "/sparkline-charts", name: "Sparkline Chart", allowed: false },
    // { path: "/charts-c3", name: "C3 Chart", allowed: false },
    // { path: "/charts-knob", name: "Jquery Knob Chart", allowed: false },
    // { path: "/tables-basic", name: "Basic Tables", allowed: false },
    // { path: "/tables-datatable", name: "Data Tables", allowed: false },
    // { path: "/tables-responsive", name: "Responsive Table", allowed: false },
    // { path: "/tables-editable", name: "Editable Table", allowed: false },
    // { path: "/icons-materialdesign", name: "Material Design", allowed: false },
    // { path: "/icons-ion", name: "Ion Icons", allowed: false },
    // { path: "/icons-fontawesome", name: "Font Awesome", allowed: false },
    // { path: "/icons-themify", name: "Themify Icons", allowed: false },
    // { path: "/icons-dripicons", name: "Dripicons", allowed: false },
    // { path: "/icons-typicons", name: "Typicons Icons", allowed: false },
    // { path: "/maps-google", name: "Google Maps", allowed: false },
    // { path: "/maps-vector", name: "Vector Maps", allowed: false },
    // { path: "/maps-leaflet", name: "Leaflet Maps", allowed: false },
    // { path: "/login", name: "Login", allowed: false },
    // { path: "/pages-register", name: "Register", allowed: false },
    // { path: "/page-recoverpw", name: "Recover Password", allowed: false },
    // { path: "/auth-lock-screen", name: "Lock Screen", allowed: false },
    // { path: "/pages-timeline", name: "Timeline", allowed: false },
    // { path: "/pages-invoice", name: "Invoice", allowed: false },
    // { path: "/pages-directory", name: "Directory", allowed: false },
    // { path: "/pages-blank", name: "Blank Page", allowed: false },
    // { path: "/pages-404", name: "Error 404", allowed: false },
    // { path: "/pages-500", name: "Error 500", allowed: false },
    // { path: "/#", name: "Level 1.1", allowed: false },
    // { path: "/#", name: "Level 2.1", allowed: false },
    // { path: "/#", name: "Level 2.2", allowed: false },
  ];

  const isPageActive = (pageLink) => {

    return location.pathname.startsWith(pageLink);

  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            {menuItems.map((item, index) => (

              (isAllowed(item.permission)) && (
                <React.Fragment key={index}>
                  {item.submenu ? (
                    <li>
                      <a href="#!" className={"has-arrow waves-effect"} onClick={toggleDropdown}>
                        <i className={item.icon}></i>
                        <span>{item.label}</span>
                      </a>
                      <ul className={`sub-menu ${isDropdownOpen ? 'mm-collapse mm-show' : 'mm-collapse'}`} aria-expanded={isDropdownOpen}>
                        {item.submenu.map((subitem, subindex) => (
                          <li key={subindex}>
                            <Link to={subitem.to} className={`waves-effect ${isPageActive(subitem.to) && "text-white"}`}>{subitem.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    <li>
                      <Link to={item.to} className={`waves-effect ${isPageActive(item.to) && "text-white"}`}>
                        <i className={`${item.icon} ${isPageActive(item.to) && "text-white"}`}></i>
                        <span className="active">{item.label}</span>
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              )
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default SidebarContent;
