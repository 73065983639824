import React, { useEffect, useRef, useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import ReactInputMask from "react-input-mask";
import { useAuth } from "hooks/useAuth";
import { get, post } from "helpers/api_helper";
import { useNavigate, useParams } from "react-router-dom";
import hasImage from "common/hasImage";
import toast from "react-hot-toast";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";

const UpdateTerraUser = (props) => {

  const [formData, setFormData] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [functions, setFunctions] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [brStates, setBrStates] = useState([]);

  const navigate = useNavigate();

  const { userData } = useAuth();

  const fileInputRef = useRef(null);

  const { id } = useParams();

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Usuários Terra", link: "/usuarios-terra" },
    { title: "Atualizar Usuário Terra", link: "#" }
  ];

  const getUserData = () => {
    get('user_data.php?id=' + id)
      .then(res => {
        if (res) {
          setFormData(res.data);
          setIsLoading(false)
        };
      })
  };

  const getUserFunctions = () => {
    get('user_functions.php')
      .then(res => {
        if (res) {
          setFunctions(res.functions);
        };
      })
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const getUF = () => {
    get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
      .then(res => {
        if (res) {
          setBrStates(res);
        }
      })
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageLoading(true); // Indica que a imagem está sendo carregada
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const aspectRatio = img.width / img.height;
          let newWidth, newHeight;

          // Definir dimensões de corte mantendo a proporção 1:1 (500x500)
          if (aspectRatio > 1) {
            newWidth = 500;
            newHeight = 500 / aspectRatio;
          } else {
            newWidth = 500 * aspectRatio;
            newHeight = 500;
          };

          // Centralizar a imagem no canvas
          const offsetX = (500 - newWidth) / 2;
          const offsetY = (500 - newHeight) / 2;

          // Desenhar a imagem cortada no canvas
          canvas.width = 500;
          canvas.height = 500;
          ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);

          // Converter o canvas de volta para o formato base64
          const croppedImage = canvas.toDataURL('image/jpeg');

          setFormData({
            ...formData,
            image: croppedImage,
          });

          setImageLoading(false); // Indica que a imagem foi completamente carregada e processada
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateUser = (e) => {

    e.preventDefault();

    const payload = {
      id_admin_user: userData.id_admin,
      id_user: id,
      image: formData.image,
      cpf: formData.cpf,
      email: formData.email,
      password: formData.password,
      full_name: formData.full_name,
      address: formData.address || '',
      number: formData.number || '',
      complement: formData.complement || '',
      neighborhood: formData.neighborhood || '',
      city: formData.city || '',
      state: formData.state || '',
      zip_code: formData.zip_code || '',
      whatsapp: formData.whatsapp,
      cellphone: formData.cellphone,
      function_id: formData.function_id, // Substitua pelo valor desejado
      terra_user_permissions: {
        visualize_users: formData.visualize_users,
        manage_users: formData.manage_users,
      },
      orders_permissions: {
        visualize_orders: formData.visualize_orders,
        manage_orders: formData.manage_orders,
        create_order: formData.create_order,
      },
      products_permissions: {
        visualize_products: formData.visualize_products,
        manage_products: formData.manage_products,
      },
      customers_permissions: {
        visualize_customers: formData.visualize_customers,
        manage_customers: formData.manage_customers,
        create_customer: formData.create_customer,
      },
      statistics_permissions: {
        visualize_statistics: formData.visualize_statistics,
        generate_reports: formData.generate_reports,
      },
    };

    post('/user_update.php', payload)
      .then(res => {
        if (res.success) {
          toast.success(res.message)
          navigate(`/detalhes-usuario-terra/${id}`);
        } else {
          toast.error(res.message)
        };
      })
      .catch(error => {
        toast.error('Erro de conexão! Tente novamente mais tarde.')
        console.log(error);
      });
  };

  useEffect(() => {

    props.setBreadcrumbItems('Atualizar Usuário Terra', breadcrumbItems);

    getUserFunctions();
    getUserData();
    getUF();

  }, []);

  useEffect(() => {

    const formatted_zip_code = formData.zip_code?.replace(/\D/g, '');

    if (formatted_zip_code?.length > 7) {
      get(`https://viacep.com.br/ws/${formatted_zip_code}/json/`)
        .then(res => {
          if (res) {
            setFormData({ ...formData, address: res.logradouro, neighborhood: res.bairro, city: res.localidade, state: res.uf });
          };
        });
    };

  }, [formData.zip_code]);

  return (<LoadingHandlerScreen isLoading={isLoading} component={
    <React.Fragment>

      <MetaTags>
        <title>Atualizar Usuário Terra | Grupo Terra</title>
      </MetaTags>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} sm={6} md={4} lg={3} className="d-flex justify-content-center align-items-center">
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <Button onClick={handleImageClick}>
                    {imageLoading ? "Carregando..." : (
                      <img
                        className="img-thumbnail"
                        alt="Imagem"
                        width="200"
                        src={hasImage(formData.image)}
                      />
                    )}
                  </Button>
                </Col>
                <Col xs={12} sm={6} md={8} lg={9}>
                  <div className="mt-5 mt-lg-4">
                    <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informações Principais</h5>

                    <form>
                      <div className="row mb-4">
                        <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Nome Completo</label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control" id="horizontal-firstname-input" name="full_name"
                            onChange={handleInputChange} value={formData.full_name} />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">Whatsapp</label>
                        <div className="col-sm-9">
                          <ReactInputMask
                            mask='(99) 9 9999-9999'
                            className="form-control"
                            type="tel"
                            id="whatsapp"
                            placeholder="Insira o Whatsapp do colaborador *"
                            name="whatsapp" value={formData.whatsapp}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label htmlFor="horizontal-cpf-input" className="col-sm-3 col-form-label">CPF</label>
                        <div className="col-sm-9">
                          <ReactInputMask
                            mask='999.999.999-99'
                            className="form-control"
                            type="tel"
                            id="cpf"
                            placeholder="Insira o CPF do colaborador *"
                            name="cpf" value={formData.cpf}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Contato Informações Importantes</CardTitle>
              <Row className="mb-3">
                <label
                  htmlFor="phone"
                  className="col-md-2 col-form-label"
                >
                  Telefone
                </label>
                <div className="col-md-10">
                  <ReactInputMask
                    mask='(99) 9999-9999'
                    className="form-control"
                    type="tel"
                    id="phone"
                    placeholder="Insira um telefone do colaborador *"
                    name="cellphone" value={formData.cellphone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label htmlFor="cargo" className="col-md-2 col-form-label">
                  Função
                </label>
                <div className="col-md-10">
                  <select className="form-control" id="funcao" name="function_id" value={formData.function_id} onChange={handleInputChange}>
                    {
                      functions.map(func => (
                        <option value={func.id}>{func.title}</option>
                      ))
                    }
                  </select>
                </div>
              </Row>
              <Row className="mb-3">
                <label htmlFor="horizontal-email-input" className="col-sm-2 col-form-label">E-mail</label>
                <div className="col-sm-10">
                  <input type="email" className="form-control" id="horizontal-email-input" name="email" value={formData.email} onChange={handleInputChange} autoComplete="off"/>
                </div>
              </Row>
              <Row className="mb-3">
                <label htmlFor="horizontal-email-input" className="col-sm-2 col-form-label">Senha</label>
                <div className="col-sm-10">
                  <InputGroup>
                    <Input type={isVisible ? "text" : 'password'} className="form-control" id="horizontal-email-input" name="password" value={formData.password} onChange={handleInputChange} autoComplete="off" />
                    <InputGroupAddon addonType="append"><Button onClick={() => setIsVisible(!isVisible)} color="primary"><i className={"mdi mdi-eye" + (isVisible ? "-off" : '')}></i></Button></InputGroupAddon>
                  </InputGroup>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Endereço</CardTitle>
              <Row>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="address" className="col-form-label">CEP</label>
                  <div className="col-md-10">
                    <ReactInputMask
                      mask='99.999-999'
                      id="zip_code"
                      className="form-control"
                      type="text"
                      name="zip_code"
                      value={formData.zip_code}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="address" className="col-form-label">Endereço</label>
                  <div className="col-md-10">
                    <input
                      id="address"
                      className="form-control"
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="number" className="col-form-label">Número</label>
                  <div className="col-md-10">
                    <input
                      id="number"
                      className="form-control"
                      type="text"
                      name="number"
                      value={formData.number}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="complement" className="col-form-label">Complemento</label>
                  <div className="col-md-10">
                    <input
                      id="complement"
                      className="form-control"
                      type="text"
                      name="complement"
                      value={formData.complement}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="neighborhood" className="col-form-label">Bairro</label>
                  <div className="col-md-10">
                    <input
                      id="neighborhood"
                      className="form-control"
                      type="text"
                      name="neighborhood"
                      value={formData.neighborhood}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="city" className="col-form-label">Cidade</label>
                  <div className="col-md-10">
                    <input
                      id="city"
                      className="form-control"
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="state" className="col-form-label">Estado</label>
                  <div className="col-md-10">
                    <select
                      id="state"
                      className="form-control"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}>
                      <option value="">Selecione um Estado</option>
                      {
                        brStates.map(state => (
                          <option key={state.id} value={state.sigla}>{state.sigla}</option>
                        ))
                      }
                    </select>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col >
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Permissões de Usuário</CardTitle>
              <Row>
                <Col md={4}>
                  <div>
                    <h5 className="font-size-14 mb-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i> Usuários Terra
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="usuariosTerraCheck1"
                        name="visualize_users" checked={formData.visualize_users}
                        onChange={handleInputChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="usuariosTerraCheck1"
                      >
                        Visualiza outros usuários
                      </label>
                    </div>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="manage_users" checked={formData.manage_users}
                        onChange={handleInputChange}
                        id="usuariosTerraCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="usuariosTerraCheck2"
                      >
                        Gerencia outros usuários
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <h5 className="font-size-14 mb-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i> Pedidos
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="visualize_orders" checked={formData.visualize_orders}
                        onChange={handleInputChange}
                        id="pedidosCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pedidosCheck1"
                      >
                        Visualiza pedidos
                      </label>
                    </div>
                    <div className="form-check form-check-end mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="manage_orders" checked={formData.manage_orders}
                        onChange={handleInputChange}
                        id="pedidosCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pedidosCheck2"
                      >
                        Gerencia pedidos
                      </label>
                    </div>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="create_order" checked={formData.create_order}
                        onChange={handleInputChange}
                        id="pedidosCheck3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pedidosCheck3"
                      >
                        Cria pedido
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <h5 className="font-size-14 mb-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i> Produtos
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="visualize_products" checked={formData.visualize_products}
                        onChange={handleInputChange}
                        id="produtosCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="produtosCheck1"
                      >
                        Visualiza produtos
                      </label>
                    </div>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="manage_products" checked={formData.manage_products}
                        onChange={handleInputChange}
                        id="produtosCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="produtosCheck2"
                      >
                        Gerencia produtos
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <h5 className="font-size-14 my-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i> Clientes
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="visualize_customers" checked={formData.visualize_customers}
                        onChange={handleInputChange}
                        id="clientesCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="clientesCheck1"
                      >
                        Visualiza clientes
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="create_customer" checked={formData.create_customer}
                        onChange={handleInputChange}
                        id="clientesCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="clientesCheck2"
                      >
                        Cadastra clientes
                      </label>
                    </div>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="manage_customers" checked={formData.manage_customers}
                        onChange={handleInputChange}
                        id="clientesCheck3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="clientesCheck3"
                      >
                        Gerencia clientes
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <h5 className="font-size-14 my-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i> Estatísticas
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="visualize_statistics" checked={formData.visualize_statistics}
                        onChange={handleInputChange}
                        id="estatisticasCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="estatisticasCheck1"
                      >
                        Visualiza KPIs
                      </label>
                    </div>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="generate_reports" checked={formData.generate_reports}
                        onChange={handleInputChange}
                        id="estatisticasCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="estatisticasCheck2"
                      >
                        Gera relatórios
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <button onClick={handleUpdateUser} className="btn btn-primary w-100">Editar este usuário</button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  } />
  );
};

export default connect(null, { setBreadcrumbItems })(UpdateTerraUser);