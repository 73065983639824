import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button, Badge } from "reactstrap"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import "./datatables.scss"
import { Link } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { post } from "helpers/api_helper";
import hasImage from "common/hasImage";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";

const ActionButton = ({ id }) => {
  return (
    <Link to={`/carrinhos-detalhes/${id}`}>
      <Button color="primary" size="sm" className="waves-effect waves-light">
        <i className="mdi mdi-magnify"></i>
      </Button>
    </Link>
  )
};

const ShoppingCarts = (props) => {

  const [cartList, setCartList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { userData: { id_admin } } = useAuth();

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Carrinhos", link: "#" },
  ];

  useEffect(() => {

    props.setBreadcrumbItems('Carrinhos', breadcrumbItems);

    const fetchData = async () => {
      const payload = { id_admin_user: id_admin };
      const response = await post('cart_list.php', payload);

      if (response.success) {
        const origin_cart = response.cart;
        const temp_cart = origin_cart.map(obj => {
          return {
            ...obj,
            acao: <ActionButton id={obj.id_cart} />,
            image: <img alt={obj.buyer_name} className="avatar-xs me-2 rounded-circle" src={hasImage(obj.image)} />,
            selected_products: <Badge className='bg-success'>{obj.selected_products}</Badge>,
            removed_products: <Badge className='bg-danger'>{obj.removed_products}</Badge>
          }
        })
        setCartList(temp_cart);
        setIsLoading(false);
      };
    };

    fetchData();
  }, [id_admin]);

  const data = {
    columns: [
      {
        label: "Imagem",
        field: "image",
        sort: "asc",
        width: 150,
      },
      {
        label: "Comprador",
        field: "buyer_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Empresa",
        field: "company_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Valor Atual",
        field: "cart_value",
        sort: "asc",
        width: 100,
      },
      {
        label: "Produtos Selecionados",
        field: "selected_products",
        sort: "asc",
        width: 100,
      },
      {
        label: "Produtos Removidos",
        field: "removed_products",
        sort: "asc",
        width: 100,
      },
      {
        label: "Data de Abertura",
        field: "cart_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ação",
        field: "acao",
        width: 100,
        sort: "disabled"
      },
    ],
    rows: cartList,
  };

  return <LoadingHandlerScreen isLoading={isLoading} component={
    <React.Fragment>

      <MetaTags>
        <title>Carrinhos | Terra</title>
      </MetaTags>

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Pesquisar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive bordered data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  } />
};

export default connect(null, { setBreadcrumbItems })(ShoppingCarts);