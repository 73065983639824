import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { MDBDataTable, MDBInput } from "mdbreact";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import "./datatables.scss";
import { Link } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { post } from "helpers/api_helper";
import hasImage from "common/hasImage";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";

const ActionButton = ({ id }) => {
  return (
    <>
      <Link to={`/produto-detalhes/${id}`}>
        <Button color="primary" size="sm" className="waves-effect waves-light">
          <i className="mdi mdi-magnify"></i>
        </Button>
      </Link>
      {" "}
      <Link to={`/produto-editar/${id}`}>
        <Button color="primary" size="sm" className="waves-effect waves-light">
          <i className="mdi mdi-pencil"></i>
        </Button>
      </Link>
    </>
  )
};

const Products = (props) => {
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchArgument, setSearchArgument] = useState('');
  const [totalPages, setTotalPages] = useState(0);

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Produtos", link: "#" },
  ];

  const { userData: { id_admin } } = useAuth();

  const fetchData = async (page, argument) => {

    setIsLoading(true);
    const payload = { id_admin_user: id_admin, page: page, search: argument };
    const response = await post('products_list.php', payload);

    if (response.success) {
      const origin_products = response.products;
      const temp_products = origin_products.map(obj => {
        return {
          ...obj,
          acao: <ActionButton id={obj.id} />,
          image: <img alt={obj.name} className="avatar-sm rounded me-2" src={hasImage(obj.image)} />,
        }
      })

      setProductList(temp_products);
      setTotalPages(response.total_pages);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    props.setBreadcrumbItems('Produtos', breadcrumbItems);
  }, [props]);

  useEffect(() => {
    fetchData(currentPage, searchArgument);
  }, [id_admin, currentPage, searchArgument]);

  const data = {
    columns: [
      {
        label: "Imagem",
        field: "image",
        sort: "asc",
        width: 100,
      },
      {
        label: "ID",
        field: "product_id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Nome",
        field: "product_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "NCM",
        field: "ncm",
        sort: "asc",
        width: 100,
      },
      {
        label: "Categoria",
        field: "category",
        sort: "asc",
        width: 150,
      },
      {
        label: "Preço Unitário",
        field: "price",
        sort: "asc",
        width: 100,
      },
      {
        label: "Ação",
        field: "acao",
        sort: "asc",
        width: 100,
      },
    ],
    rows: productList,
  };

  return (

    <React.Fragment>
      <MetaTags>
        <title>Produtos | Terra</title>
      </MetaTags>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <input value={searchArgument} onChange={e => setSearchArgument(e.target.value)} type="text" placeholder="Buscar produtos na base de dados" class="form-control mb-3" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
              {/* <MDBInput /> */}
              <LoadingHandlerScreen isLoading={isLoading} component={
                <MDBDataTable noBottomColumns={true} pagesAmount={totalPages} onPageChange={e => setCurrentPage(e.activePage)} infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Filtrar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive bordered data={data} />
              } />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(Products);
