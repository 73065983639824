import React, { useEffect, useRef, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import ReactInputMask from "react-input-mask";
import hasImage from "common/hasImage";
import { get } from "helpers/api_helper";
import { MDBDataTable } from "mdbreact";
import { Link, useParams } from "react-router-dom";

const ActionButton = ({ id }) => {
  return (
    <Link to={`/colaborador-detalhes/${id}`}>
      <Button color="primary" size="sm" className="waves-effect waves-light">
        <i className="mdi mdi-magnify"></i>
      </Button>
    </Link>
  )
}

const BranchDetails = (props) => {

  const { id } = useParams();

  const [brStates, setBrStates] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [terraSellers, setTerraSellers] = useState([]);

  const [users, setUsers] = useState([]);

  const [formData, setFormData] = useState({
    cnpj: '',
    fantasy_name: '',
    corporate_reason: '',
    corporate_email: '',
    frete: '',
    unity_modality: '',
    phone: '',
    whatsapp: '',
    payment_methods: '',
    cashback_percentage: '',
    image: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    zip_code: '',
  });

  const getUF = () => {
    get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
      .then(res => {
        if (res) {
          setBrStates(res);
        }
      })

  };

  const getTerraSellers = () => {
    get('insercao_empresa/list_all_terra_users.php')
      .then(res => {
        if (res) {
          setTerraSellers(res.users);
        };
      });
  };

  const getCompanyData = () => {

    get('list_companies/Detalhes/branches_details.php?id=' + id)
      .then(res => {
        if (res.branches.users) {

          const holding_users = res.branches.users.map(data => {
            return {
              ...data,
              image: <img alt={data.full_name} className="avatar-xs me-2 rounded-circle" src={hasImage(data.image)} />,
              action: <ActionButton id={data.id} />
            }
          });

          setUsers(holding_users);
        };
        setFormData(res.branches);

      });

  };

  const getPaymentMethods = () => {
    get('get_payment_methods.php')
      .then(res => {
        if (res) {
          setPaymentMethods(res.payment_methods);
        };
      });
  };

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Clientes", link: "/clientes" },
    { title: "Detalhes de Filial", link: "#" },
  ];

  useEffect(() => {
    props.setBreadcrumbItems('Detalhes de Filial', breadcrumbItems);

    getPaymentMethods();
    getTerraSellers();
    getCompanyData();
    getUF();

  }, []);

  const employeesData = {
    columns: [
      {
        label: "Imagem",
        field: "image",
        sort: "asc",
        width: 50,
      },
      {
        label: "Name",
        field: "full_name",
        sort: "asc",
        width: 300,
      },
      {
        label: "Whatsapp",
        field: "whatsapp",
        sort: "asc",
        width: 300,
      },
      {
        label: "Ações",
        field: "action",
        width: 150,
      },
    ],
    rows: users,
  };

  return (
    <>
      <MetaTags>
        <title>Detalhes de Cliente | Grupo Terra</title>
      </MetaTags>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} sm={6} md={4} lg={3} className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-thumbnail"
                    alt="Imagem"
                    width="200"
                    src={hasImage(formData.image)}
                  />
                </Col>
                <Col xs={12} sm={6} md={8} lg={9}>
                  <div className="mt-5 mt-lg-4">
                    <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informações Principais</h5>

                    <div className="row mb-4">
                      <label htmlFor="horizontal-cnpj-input" className="col-sm-3 col-form-label">CNPJ</label>
                      <div className="col-sm-9">
                        <ReactInputMask
                          disabled
                          mask='99.999.999/9999-99'
                          className="form-control"
                          required
                          name="cnpj"
                          value={formData.cnpj}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Nome Fantasia</label>
                      <div className="col-sm-9">
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          id="fantasy_name"
                          name="fantasy_name"
                          value={formData.fantasy_name}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Razão Social</label>
                      <div className="col-sm-9">
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          id="horizontal-firstname-input"
                          name="corporate_reason"
                          value={formData.corporate_reason}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label htmlFor="corporate_email" className="col-sm-3 col-form-label">E-mail Corporativo</label>
                      <div className="col-sm-9">
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          id="corporate_email"
                          name="corporate_email"
                          value={formData.corporate_email}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">
                Endereço
              </CardTitle>
              <Row>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="address" className="col-form-label">CEP</label>
                  <div className="col-md-10">
                    <ReactInputMask
                      disabled
                      mask='99.999-999'
                      id="zip_code"
                      className="form-control"
                      type="text"
                      name="zip_code"
                      value={formData.zip_code}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="address" className="col-form-label">Endereço</label>
                  <div className="col-md-10">
                    <input
                      disabled
                      id="address"
                      className="form-control"
                      type="text"
                      name="address"
                      value={formData.address}

                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="number" className="col-form-label">Número</label>
                  <div className="col-md-10">
                    <input
                      disabled
                      id="number"
                      className="form-control"
                      type="text"
                      name="number"
                      value={formData.number}

                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="complement" className="col-form-label">Complemento</label>
                  <div className="col-md-10">
                    <input
                      disabled
                      id="complement"
                      className="form-control"
                      type="text"
                      name="complement"
                      value={formData.complement}

                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="neighborhood" className="col-form-label">Bairro</label>
                  <div className="col-md-10">
                    <input
                      disabled
                      id="neighborhood"
                      className="form-control"
                      type="text"
                      name="neighborhood"
                      value={formData.neighborhood}

                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="city" className="col-form-label">Cidade</label>
                  <div className="col-md-10">
                    <input
                      disabled
                      id="city"
                      className="form-control"
                      type="text"
                      name="city"
                      value={formData.city}

                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="state" className="col-form-label">Estado</label>
                  <div className="col-md-10">
                    <select
                      disabled
                      id="state"
                      className="form-control"
                      name="state"
                      value={formData.state}
                    >
                      <option value="">Selecione um Estado</option>
                      {
                        brStates.map(state => (
                          <option key={state.id} value={state.sigla}>{state.sigla}</option>
                        ))
                      }
                    </select>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col className="col-sm-12 mb-3">
                  <h3>
                    Detalhes do Negócio
                  </h3>
                </Col>

                <Col className="col-sm-4 mb-3">
                  <label htmlFor="modality" className="col-form-label">Modalidade de Unidade</label>
                  <div className="col-md-10">
                    <select
                      id="unity_modality"
                      className="form-control"
                      type="text"
                      name="unity_modality"
                      value={formData.unity_modality}
                      disabled
                    >
                      <option value="1">Filial</option>
                    </select>
                  </div>
                </Col>

                <Col className="col-sm-4 mb-3">
                  <label htmlFor="modality" className="col-form-label">Vendedor Responsável</label>
                  <div className="col-md-10">
                    <select
                      disabled
                      id="seller_id"
                      className="form-control"
                      type="text"
                      name="seller_id"
                      value={formData.seller_id}
                    >
                      <option value="">Selecione um Vendedor</option>
                      {
                        terraSellers.map(seller => (
                          <option value={seller.id}>{seller.full_name}</option>
                        ))
                      }
                    </select>
                  </div>
                </Col>

                <Col className="col-sm-4 mb-3">
                  <label htmlFor="phone" className="col-form-label">Telefone</label>
                  <div className="col-md-10">
                    <ReactInputMask
                      disabled
                      mask='(99) 9999-9999'
                      className="form-control"
                      type="tel"
                      required
                      name="phone"
                      value={formData.phone}

                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="whatsapp" className="col-form-label">Whatsapp</label>
                  <div className="col-md-10">
                    <ReactInputMask
                      disabled
                      mask='(99) 9 9999-9999'
                      className="form-control"
                      type="tel"
                      required
                      name="whatsapp"
                      value={formData.whatsapp}

                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="paymentMethod" className="col-form-label">Formas de Pagamento</label>
                  <div className="col-md-10">
                    <select
                      disabled
                      id="paymentMethod"
                      className="form-control"
                      type="text"
                      name="payment_methods"
                      value={formData.payment_methods}
                    >
                      <option value="">Selecione uma Forma de Pagamento</option>
                      {
                        paymentMethods?.map(method => (
                          <option key={method.id} value={method.id}>{method.payment_name}</option>
                        ))
                      }
                    </select>
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="cashbackPercentage" className="col-form-label">Porcentagem de Cashback</label>
                  <div className="col-md-10">
                    <input
                      disabled
                      id="cashbackPercentage"
                      className="form-control"
                      type="number"
                      step="0.1"
                      name="cashback_percentage"
                      value={formData.cashback_percentage}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="discountPercentage" className="col-form-label">Porcentagem de Desconto</label>
                  <div className="col-md-10">
                    <input
                      disabled
                      id="discountPercentage"
                      className="form-control"
                      type="number"
                      step="0.1"
                      name="discount"
                      value={formData.discount}

                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="discountPercentage" className="col-form-label">Frete</label>
                  <div className="col-md-10">
                    <input
                      disabled
                      id="frete"
                      className="form-control"
                      type="number"
                      name="frete"
                      value={formData.frete}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Col >
            <Card>
              <CardBody>
                <Col className="col-sm-12 mb-3 d-flex justify-content-between align-items-center">
                  <h3>
                    Colaboradores desta unidade
                  </h3>
                </Col>
                <Col className="col-sm-12 mb-3">
                  <div className="mt-3">
                    <MDBDataTable infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Pesquisar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive bordered data={employeesData} />
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(BranchDetails);