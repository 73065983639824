import { createContext, useEffect, useState } from "react";
import { post } from "helpers/api_helper";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export const AuthContext = createContext();

const initialState = {
    isLogged: false,
    userData: {}
};

export default function AuthContextProvider({ children }) {

    const [authState, setAuthState] = useState(initialState);

    useEffect(() => {

        const localUserData = localStorage.getItem('terra_user_token');

        if (localUserData && !authState.isLogged) {

            handleSignInUser(localUserData);
        };

    }, []);

    const handleLogin = async ({ email, password }) => {
        try {
            const payload = { email, password };
            const response = await post("login.php", payload);

            if (response.success) {
                const { token } = response;

                const userResponse = await post("user_information.php", { token });

                if (userResponse.success) {

                    toast.success("Login realizado com sucesso. Seja bem-vindo!");

                    setAuthState({
                        isLogged: true,
                        userData: userResponse.data,
                    });

                    localStorage.setItem('terra_user_token', token);

                } else {
                    toast.error(response.message);
                    throw new Error(userResponse.message);
                };

            } else {
                toast.error(response.message);
                throw new Error(response.message);
            };

        } catch (error) {
            console.error("Login error:", error);
        };
    };

    const handleSignInUser = async (token) => {
        try {
            const userResponse = await post("user_information.php", { token });

            if (userResponse.success) {
                setAuthState({
                    isLogged: true,
                    userData: userResponse.data,
                });
            } else {
                throw new Error(userResponse.message);
            };

        } catch (error) {
            console.error("User information retrieval error:", error);
            return error.message;
        };
    };

    const handleLogout = () => {
        setAuthState({
            isLogged: false,
            userData: {},
        });

        localStorage.removeItem('terra_user_token');
    };

    const authContextValue = {
        handleLogin,
        handleSignInUser,
        handleLogout,
        isLogged: authState.isLogged,
        userData: authState.userData
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
};