import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";
import { useLocation } from 'react-router-dom';

const Layout = (props) => {

  const { pathname } = useLocation();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const toggleMenuCallback = () => {
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    };
  };

  useEffect(() => {

    if (props.leftSideBarTheme) {
      props.changeSidebarTheme(props.leftSideBarTheme);
    };

    if (props.layoutWidth) {
      props.changeLayoutWidth(props.layoutWidth);
    };

    if (props.leftSideBarType) {
      props.changeSidebarType(props.leftSideBarType);
    };

    if (props.topbarTheme) {
      props.changeTopbarTheme(props.topbarTheme);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          isMobile={isMobile}
        />
        <div className="main-content">
          <div className="page-content">
            <Container fluid>
              <Breadcrumb />
              {props.children}
              <Footer />
            </Container>
          </div>
        </div>
        <Footer />
      </div>
      {props.showRightSidebar ? <Rightbar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
})(Layout);