import React, { useEffect, useRef, useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import ReactInputMask from "react-input-mask";
import { useAuth } from "hooks/useAuth";
import { get, post } from "helpers/api_helper";
import { useNavigate } from "react-router-dom";
import hasImage from "common/hasImage";

const CreateTerraUser = (props) => {

  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    email: "",
    full_name: "",
    cpf: "",
    image: "",
    address: "",
    complement: "",
    street: "",
    city: "",
    zip_code: "",
    whatsapp: "",
    cellphone: "",
    function_id: "",
    visualize_users: false,
    manage_users: false,
    visualize_orders: false,
    manage_orders: false,
    create_orders: false,
    visualize_products: false,
    manage_products: false,
    visualize_customers: false,
    manage_customers: false,
    create_customer: false,
    visualize_statistics: false,
    generate_reports: false,
  });

  const [isVisible, setIsVisible] = useState(false);

  const { userData: { id_admin } } = useAuth();

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Usuários Terra", link: "/usuarios-terra" },
    { title: "Cadastrar Usuário Terra", link: "#" }
  ];

  useEffect(() => {

    props.setBreadcrumbItems('Cadastrar Usuário Terra', breadcrumbItems);

  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          image: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {

    const payload = {
      id_admin_user: id_admin,
      image: formData.image,
      cpf: formData.cpf,
      email: formData.email,
      password: "Terra@2023",
      full_name: formData.full_name,
      address: formData.address,
      complement: formData.complement,
      street: formData.street,
      city: formData.city,
      zip_code: formData.zip_code,
      whatsapp: formData.whatsapp,
      cellphone: formData.cellphone,
      function_id: 1,
      terra_user_permissions: {
        visualize_users: formData.visualize_users,
        manage_users: formData.manage_users,
      },
      orders_permissions: {
        visualize_orders: formData.visualize_orders,
        manage_orders: formData.manage_orders,
        create_order: formData.create_orders,
      },
      products_permissions: {
        visualize_products: formData.visualize_products,
        manage_products: formData.manage_products,
      },
      customers_permissions: {
        visualize_customers: formData.visualize_customers,
        manage_customers: formData.manage_customers,
        create_customer: formData.create_customer,
      },
      statistics_permissions: {
        visualize_statistics: formData.visualize_statistics,
        generate_reports: formData.generate_reports,
      },
    };

    post('user_insertion.php', payload)
      .then(res => res.data.success && navigate('/usuarios-terra'));
  }

  return (
    <React.Fragment>

      <MetaTags>
        <title>Detalhes de Usuário Terra | Grupo Terra</title>
      </MetaTags>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} sm={6} md={4} lg={3} className="d-flex justify-content-center align-items-center">
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <Button onClick={handleImageClick}>
                    <img
                      className="img-thumbnail"
                      alt="Imagem"
                      width="200"
                      src={formData.image ? formData.image : hasImage()}
                    />
                  </Button>
                </Col>
                <Col xs={12} sm={6} md={8} lg={9}>
                  <div className="mt-5 mt-lg-4">
                    <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informações Principais</h5>

                    <form>
                      <div className="row mb-4">
                        <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Nome Completo</label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control" id="horizontal-firstname-input" name="full_name" value={formData.full_name} onChange={handleInputChange} />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label htmlFor="horizontal-email-input" className="col-sm-3 col-form-label">E-mail</label>
                        <div className="col-sm-9">
                          <input type="email" className="form-control" id="horizontal-email-input" name="email" value={formData.email} onChange={handleInputChange} />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">Whatsapp</label>
                        <div className="col-sm-9">
                          <ReactInputMask
                            mask='(99) 9 9999-9999'
                            className="form-control"
                            type="tel"
                            id="whatsapp"
                            placeholder="Insira o Whatsapp do colaborador *"
                            name="whatsapp" value={formData.whatsapp} onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row className="mb-3">
                <label
                  htmlFor="phone"
                  className="col-md-2 col-form-label"
                >
                  Telefone
                </label>
                <div className="col-md-10">
                  <ReactInputMask
                    mask='(99) 9999-9999'
                    className="form-control"
                    type="tel"
                    id="phone"
                    placeholder="Insira um telefone do colaborador *"
                    name="cellphone" value={formData.cellphone} onChange={handleInputChange}
                    required
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <label htmlFor="horizontal-email-input" className="col-sm-2 col-form-label">Senha *</label>
                <div className="col-sm-10">
                  <InputGroup>
                    <Input type={isVisible ? "text" : 'password'} className="form-control" id="horizontal-email-input" name="password" value={formData.password} onChange={handleInputChange} />
                    <InputGroupAddon addonType="append"><Button onClick={() => setIsVisible(!isVisible)} color="primary"><i className={"mdi mdi-eye" + (isVisible ? "-off" : '')}></i></Button></InputGroupAddon>
                  </InputGroup>
                </div>
              </Row>
              <Row className="mb-3">
                <label htmlFor="cargo" className="col-md-2 col-form-label">
                  Função
                </label>
                <div className="col-md-10">
                  <select className="form-control" id="funcao" name="function_id" value={formData.function_id} onChange={handleInputChange}>
                    <option value={1}>Direção</option>
                  </select>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col >
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Permissões de Usuário</CardTitle>
              <Row>
                <Col md={4}>
                  <div>
                    <h5 className="font-size-14 mb-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i> Usuários Terra
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="usuariosTerraCheck1"
                        name="visualize_users" checked={formData.visualize_users} onChange={handleInputChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="usuariosTerraCheck1"
                      >
                        Visualiza outros usuários
                      </label>
                    </div>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="manage_users" checked={formData.manage_users} onChange={handleInputChange}
                        id="usuariosTerraCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="usuariosTerraCheck2"
                      >
                        Gerencia outros usuários
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <h5 className="font-size-14 mb-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i> Pedidos
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="visualize_orders" checked={formData.visualize_orders} onChange={handleInputChange}
                        id="pedidosCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pedidosCheck1"
                      >
                        Visualiza pedidos
                      </label>
                    </div>
                    <div className="form-check form-check-end mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="manage_orders" checked={formData.manage_orders} onChange={handleInputChange}
                        id="pedidosCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pedidosCheck2"
                      >
                        Gerencia pedidos
                      </label>
                    </div>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="create_orders" checked={formData.create_orders} onChange={handleInputChange}
                        id="pedidosCheck3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pedidosCheck3"
                      >
                        Cria pedido
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <h5 className="font-size-14 mb-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i> Produtos
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="visualize_products" checked={formData.visualize_products} onChange={handleInputChange}
                        id="produtosCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="produtosCheck1"
                      >
                        Visualiza produtos
                      </label>
                    </div>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="manage_products" checked={formData.manage_products} onChange={handleInputChange}
                        id="produtosCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="produtosCheck2"
                      >
                        Gerencia produtos
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <h5 className="font-size-14 my-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i> Clientes
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="visualize_customers" checked={formData.visualize_customers} onChange={handleInputChange}
                        id="clientesCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="clientesCheck1"
                      >
                        Visualiza clientes
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="create_customer" checked={formData.create_customer} onChange={handleInputChange}
                        id="clientesCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="clientesCheck2"
                      >
                        Cadastra clientes
                      </label>
                    </div>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="manage_customers" checked={formData.manage_customers} onChange={handleInputChange}
                        id="clientesCheck3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="clientesCheck3"
                      >
                        Gerencia clientes
                      </label>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <h5 className="font-size-14 my-4">
                      <i className="mdi mdi-arrow-right text-primary mr-1"></i> Estatísticas
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="visualize_statistics" checked={formData.visualize_statistics} onChange={handleInputChange}
                        id="estatisticasCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="estatisticasCheck1"
                      >
                        Visualiza KPIs
                      </label>
                    </div>
                    <div className="form-check form-check-end">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="generate_reports" checked={formData.generate_reports} onChange={handleInputChange}
                        id="estatisticasCheck2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="estatisticasCheck2"
                      >
                        Gera relatórios
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="col-auto">
                  <button onClick={handleSubmit} className="btn btn-primary">Cadastrar Usuário Terra</button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(CreateTerraUser);