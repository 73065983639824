import React from 'react';
import { Table, Card, CardBody, Button, Badge } from "reactstrap";

//Import Images
import { Link } from 'react-router-dom';
import hasImage from 'common/hasImage';
import NoRecords from 'components/Common/NoRecords';

function LatestTransactions({ carts }) {

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                        <h4 className="card-title">5 últimos carrinhos abertos</h4>
                        <Link to={'/produtos'}>
                            <Button color="primary" size="sm" className="waves-effect waves-light">
                                <i className="mdi mdi-cart-variant"></i> Ver mais carrinhos
                            </Button>
                        </Link>
                    </div>
                    <div className="table-responsive">
                        {
                            carts?.length > 1 ?
                                <Table className="align-middle table-centered table-vertical table-nowrap">
                                    <tbody>
                                        {
                                            carts.map((cart, key) =>
                                                <tr key={key}>
                                                    <td>
                                                        {cart.id_cart}
                                                    </td>
                                                    <td>
                                                        <img src={hasImage(cart.image)} alt="user" className="avatar-xs rounded-circle me-2" /> {cart.buyer_name}
                                                    </td>
                                                    <td>
                                                        {cart.company_name}
                                                    </td>
                                                    <td>
                                                        R${cart.cart_value}
                                                    </td>
                                                    <td>
                                                        <Badge className='bg-success'>{cart.selected_products}</Badge>
                                                    </td>
                                                    <td>
                                                        <Badge className='bg-danger'>{cart.removed_products}</Badge>
                                                    </td>
                                                    <td>
                                                        {cart.cart_date}
                                                    </td>
                                                    <td>
                                                        <Link to={`/carrinhos-detalhes/${cart.id_cart}`}>
                                                            <Button color="primary" size="sm" className="waves-effect waves-light">
                                                                <i className="mdi mdi-magnify"></i>
                                                            </Button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                                :
                                <NoRecords />
                        }
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default LatestTransactions;