import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Container, Row, Col, Card, CardBody, Alert } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import logoLightPng from '../../assets/images/logo-light.png';
import logoDark from '../../assets/images/logo-dark.png';
import { userForgetPassword } from '../../store/actions';
import { post } from 'helpers/api_helper';

const ForgetPasswordPage = (props) => {

  const navigate = useNavigate();

  const [step, setStep] = useState(0);

  const SendEmail = () => {

    const [email, setEmail] = useState('');

    const handleSendEmail = () => {
      post(email)
        .then((e) => {
          console.log(e);
        });
    };

    return (
      <React.Fragment>
        <MetaTags>
          <title>Forget Password | Grupo Terra</title>
        </MetaTags>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <h3 className="text-center mt-5 mb-4">
                      <Link to="/" className="d-block auth-logo">
                        <img src={logoDark} alt="" height="30" className="auth-logo-dark" />
                        <img src={logoLightPng} alt="" height="30" className="auth-logo-light" />
                      </Link>
                    </h3>
                    <div className="p-3">
                      <h4 className="text-muted font-size-18 mb-3 text-center">Redefinição de Senha</h4>
                      <div className="alert alert-info" role="alert">
                        Insira seu email e instruções serão enviadas!
                      </div>
                      {props.forgetError && props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={handleSendEmail}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Redefinir
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Lembrou dela?{' '}
                    <Link to="/login" className="text-primary">
                      Entre por aqui
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };

  const CheckCode = () => {

    return (
      <React.Fragment>
        <MetaTags>
          <title>Forget Password | Grupo Terra</title>
        </MetaTags>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <h3 className="text-center mt-5 mb-4">
                      <Link to="/" className="d-block auth-logo">
                        <img src={logoDark} alt="" height="30" className="auth-logo-dark" />
                        <img src={logoLightPng} alt="" height="30" className="auth-logo-light" />
                      </Link>
                    </h3>
                    <div className="p-3">
                      <h4 className="text-muted font-size-18 mb-3 text-center">Redefinição de Senha</h4>
                      <div className="alert alert-info" role="alert">
                        Insira seu email e instruções serão enviadas!
                      </div>
                      {props.forgetError && props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={handleValidSubmit}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Redefinir
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Lembrou dela?{' '}
                    <Link to="/login" className="text-primary">
                      Entre por aqui
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };

  const ChangePassword = () => {

    return (
      <React.Fragment>
        <MetaTags>
          <title>Forget Password | Grupo Terra</title>
        </MetaTags>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <h3 className="text-center mt-5 mb-4">
                      <Link to="/" className="d-block auth-logo">
                        <img src={logoDark} alt="" height="30" className="auth-logo-dark" />
                        <img src={logoLightPng} alt="" height="30" className="auth-logo-light" />
                      </Link>
                    </h3>
                    <div className="p-3">
                      <h4 className="text-muted font-size-18 mb-3 text-center">Redefinição de Senha</h4>
                      <div className="alert alert-info" role="alert">
                        Insira seu email e instruções serão enviadas!
                      </div>
                      {props.forgetError && props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={handleValidSubmit}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Redefinir
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Lembrou dela?{' '}
                    <Link to="/login" className="text-primary">
                      Entre por aqui
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };

  const ResultChange = () => {

    return (
      <React.Fragment>
        <MetaTags>
          <title>Forget Password | Grupo Terra</title>
        </MetaTags>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <h3 className="text-center mt-5 mb-4">
                      <Link to="/" className="d-block auth-logo">
                        <img src={logoDark} alt="" height="30" className="auth-logo-dark" />
                        <img src={logoLightPng} alt="" height="30" className="auth-logo-light" />
                      </Link>
                    </h3>
                    <div className="p-3">
                      <h4 className="text-muted font-size-18 mb-3 text-center">Redefinição de Senha</h4>
                      <div className="alert alert-info" role="alert">
                        Insira seu email e instruções serão enviadas!
                      </div>
                      {props.forgetError && props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={handleValidSubmit}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Redefinir
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Lembrou dela?{' '}
                    <Link to="/login" className="text-primary">
                      Entre por aqui
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };

  switch (step) {

    case 0:
      return <SendEmail />

    case 1:
      return <CheckCode />

    case 2:
      return <ChangePassword />

    default:
      return <ResultChange />

  };
};

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  userForgetPassword: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default connect(mapStateToProps, { userForgetPassword })(ForgetPasswordPage);