export default function hasImage(img) {
    if (!img) {
        return 'https://matrixterra.com.br/data/company_images/logo.jpg'; // Retorna a imagem padrão se a imagem estiver vazia
    }

    const imageArray = img.split(',');

    if (imageArray.length > 1) {
        return img;
    }

    return 'data:image/jpeg;base64,' + img;
}