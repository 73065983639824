import hasImage from "common/hasImage";
import React, { useState } from "react";
import { Carousel, CarouselItem, CarouselControl } from "reactstrap";

const ProductImages = ({ images = [] }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const onExiting = () => {
        setAnimating(true);
    };

    const onExited = () => {
        setAnimating(false);
    };

    const next = () => {
        if (animating) return;
        const nextIndex =
            activeIndex === images.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const slides = images.map((item, index) => (
        <CarouselItem
            onExiting={onExiting}
            onExited={onExited}
            key={index}
            active={index === activeIndex}
            className="w-100 h-100"
        >
            <img  src={hasImage(item.image)} className="d-block img-fluid w-100 h-100" alt={'Imagem de Produto'} />
        </CarouselItem>
    ));

    return (
        <React.Fragment>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                ride={false}  // Altera para 'false' para remover a transição
                className="w-100 h-auto"
            >
                {slides}
                <CarouselControl
                    style={{ color: 'black' }}
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    style={{ color: 'black' }}
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel>
        </React.Fragment>
    );
};

export default ProductImages;
