import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom"; // Importe useNavigate do react-router-dom v6
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

const Sidebar = () => {
  const navigate = useNavigate(); // Use useNavigate para navegação

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <SidebarContent navigate={navigate} />
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, {})(withTranslation()(Sidebar))