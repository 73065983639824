import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React, { useState } from 'react';

import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import logoLightPng from '../../assets/images/logo-light.png';
import logoDark from '../../assets/images/logo-dark.png';
import { useAuth } from 'hooks/useAuth';

const Login = () => {

  const [message, setMessage] = useState(null);

  const { handleLogin } = useAuth();

  const handleValidSubmit = (values) => {

    handleLogin(values);

  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login - Terra</title>
      </MetaTags>
      <div className="account-pages my-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="80" className="auth-logo-dark" />
                      <img src={logoLightPng} alt="" height="80" className="auth-logo-light" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">Seja bem-vindo!</h4>
                    <p className="text-muted text-center">Entre em sua conta para acessar o painel Terra</p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(event, values) => {
                        handleValidSubmit(values);
                      }}
                    >
                      {message ? (
                        <Alert color="danger">{message}</Alert>
                      ) : null}
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="E-mail"
                          className="form-control"
                          placeholder="seuemail@meuemail.com"
                          type="email"
                          validate={{
                            required: { value: true, errorMessage: 'Por favor, insira um e-mail!' },
                            email: { value: true, errorMessage: 'Por favor, insira um e-mail válido!' },
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Senha"
                          type="password"
                          placeholder="*********"
                          validate={{
                            required: { value: true, errorMessage: 'Por favor, insira uma senha!' },
                          }}
                        />
                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-6">
                          <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock"></i>Esqueceu a senha?</Link>
                        </div>
                        <div className="col-6 text-end">
                          <button type='submit' className="btn btn-primary w-md waves-effect waves-light">
                            Entrar
                          </button>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="text-center">
                <p>
                  © {new Date().getFullYear()} - Grupo Terra
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;