import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import hasImage from "common/hasImage";

const ProfileMenu = () => {

  const { handleLogout, userData } = useAuth();

  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
          <img className="rounded-circle header-profile-user" src={hasImage(userData.image)} alt="Header Avatar" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag={Link} to="/perfil">
            <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
            Meu Perfil
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="#" onClick={() => handleLogout()} className="dropdown-item text-danger">
            <i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger" />
            <span>Sair</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default ProfileMenu;