import api from "services/api";

export async function get(url, config = {}) {
  return await api.get(url, { ...config })
    .then(response => response.data)
    .catch(error => error);
};

export async function post(url, data, config = {}) {
  return api
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => error);
};

export async function put(url, data, config = {}) {
  return api
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => error);
};

export async function del(url, config = {}) {
  return await api
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(error => error);
};
