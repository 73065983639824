import { post } from 'helpers/api_helper';
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CompanyContext = createContext();

export const CompanyContextProvider = ({ children }) => {

    const [holdingData, setHoldingData] = useState(null);
    const [matrizData, setMatrizData] = useState(null);
    const [filialData, setFilialData] = useState(null);

    const navigate = useNavigate();

    const updateHoldingData = (data) => {console.log(data); setHoldingData(data)};
    const updateMatrizData = (data) => setMatrizData(data);
    const updateFilialData = (data) => setFilialData(data);
    const insertHoldingEmployee = (employee) => setHoldingData({ ...holdingData, users: [...holdingData.users, employee] });

    const handleCreateCustomer = () => {

        const payload = holdingData;

        post('insercao_empresa/insert_holding_and_users.php', payload)
            .then(res => {
                if (res.success) {
                    navigate('/clientes');
                }
            });
    };

    const contextValue = {
        holdingData,
        updateHoldingData,
        matrizData,
        updateMatrizData,
        filialData,
        updateFilialData,
        insertHoldingEmployee,
        handleCreateCustomer
    };

    return (
        <CompanyContext.Provider value={contextValue}>
            {children}
        </CompanyContext.Provider>
    );
};

// Crie um hook personalizado para usar o contexto
export const useCompanyContext = () => {
    const context = useContext(CompanyContext);
    return context;
};