import React from "react"
import Login from "pages/Authentication/Login";
import NonAuthLayout from "components/NonAuthLayout";
import VerticalLayout from "components/VerticalLayout";

const Authmiddleware = ({
  defaultComponent,
  loggedComponent,
  isLogged
}) => {

  if (!isLogged) {
    return (
      <NonAuthLayout>
        {defaultComponent}
      </NonAuthLayout>
    );
  };

  return (
    <VerticalLayout>
      {loggedComponent}
    </VerticalLayout>
  );
};

export default Authmiddleware;