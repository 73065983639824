import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Badge
} from "reactstrap";
import { Link, useParams } from "react-router-dom";

import MetaTags from 'react-meta-tags';

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

//Import Images
import { useAuth } from 'hooks/useAuth';
import { get } from 'helpers/api_helper';
import hasImage from 'common/hasImage';
import LoadingHandlerScreen from 'components/Common/LoadingHandlerScreen';

const StatusCell = ({ status }) => {

    if (status == 0) {
        return (
            <Badge className={"rounded-pill bg-danger"}>Removido</Badge>
        );
    };

    return (
        <Badge className={"rounded-pill bg-success"}>No carrinho</Badge>
    );
};

const PagesInvoice = (props) => {

    const [cartData, setCartData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { userData: { id_admin } } = useAuth();

    const { id } = useParams();

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Carrinhos", link: "/carrinhos" },
        { title: "Detalhes de Carrinho", link: "#" },
    ];

    useEffect(() => {

        props.setBreadcrumbItems('Detalhes de Carrinho', breadcrumbItems);

        const fetchData = async () => {

            const response = await get(`cart_details.php?id_cart=${id}`);

            if (response.success) {
                setCartData(response.cart);
                setCompanyData(response.cart.customer_data);
                setIsLoading(false);
            };
        };

        fetchData();
    }, [id_admin]);

    const printInvoice = () => {
        window.print();
    };

    return (
        <LoadingHandlerScreen isLoading={isLoading} component={<React.Fragment>
            <MetaTags>
                <title>Detalhes de Carrinho | Grupo Terra</title>
            </MetaTags>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>

                            <Row>
                                <Col xs="12">
                                    <div className="invoice-title">
                                        <h3 className="mt-0">
                                            <img src={hasImage(companyData?.company_logo)} alt="logo" height="100" />
                                        </h3>
                                    </div>
                                    <hr />
                                    <Row>
                                        <Col md="6">
                                            <address>
                                                <strong>Empresa:</strong><br />
                                                {companyData?.company_name}
                                            </address>
                                        </Col>
                                        <Col md="6" className="text-md-end">
                                            <address>
                                                <strong>Responsável:</strong><br />
                                                {companyData?.responsible_name + " (" + companyData?.responsible_function + ")"}
                                            </address>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" className="mt-4 text-end">
                                            <address>
                                                <strong>
                                                    Data de Abertura:
                                                </strong>
                                                <br />
                                                {cartData?.cart_date}
                                            </address>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12">
                                    <div>
                                        <div className="p-2">
                                            <h3 className="font-size-16"><strong>Lista de Produtos</strong></h3>
                                        </div>
                                        <div className="">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <td><strong>ID</strong></td>
                                                            <td>Imagem</td>
                                                            <td><strong>Nome</strong></td>
                                                            <td><strong>NCM</strong></td>
                                                            <td><strong>Status</strong></td>
                                                            <td className="text-center"><strong>Preço</strong></td>
                                                            <td className="text-center"><strong>Quantidade</strong></td>
                                                            <td className="text-end"><strong>Total</strong></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            cartData?.products_data?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.product_id}</td>
                                                                    <td>
                                                                        <img alt={item.product_name} className="rounded avatar-sm card-img" src={hasImage(item.image)} />
                                                                    </td>
                                                                    <td>{item.product_name}</td>
                                                                    <td>{item.ncm}</td>
                                                                    <td><StatusCell status={item.is_active} /></td>
                                                                    <td className="text-center">{item.product_price}</td>
                                                                    <td className="text-center">{item.product_quantity}</td>
                                                                    <td className="text-end">{item.product_total_price}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line text-center">
                                                                <strong>Subtotal</strong>
                                                            </td>
                                                            <td className="thick-line text-end">{cartData?.subtotal_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line text-center">
                                                                <strong>Cashback</strong>
                                                            </td>
                                                            <td className="no-line text-end">{cartData?.discount_cashback}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line text-center">
                                                                <strong>Desconto Empresa</strong>
                                                            </td>
                                                            <td className="no-line text-end">{cartData?.discount_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line text-center">
                                                                <strong>Frete</strong>
                                                            </td>
                                                            <td className="no-line text-end">{cartData.shipping_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line text-center">
                                                                <strong>Total</strong>
                                                            </td>
                                                            <td className="no-line text-end">
                                                                <h4 className="m-0">{cartData.total_price}</h4>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="d-print-none">
                                                <div className="float-end">
                                                    <Link to="#" onClick={printInvoice} className="btn btn-info waves-effect waves-light">
                                                        <i className="mdi mdi-file-pdf"> </i>
                                                    </Link>{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>} />
    );
}

export default connect(null, { setBreadcrumbItems })(PagesInvoice);