import React, { useEffect } from 'react';
import { Row } from "reactstrap";

import MetaTags from 'react-meta-tags';

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

//Import Components
import CardUser from "./card-user";

const TerraUsers = (props) => {

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Usuários Terra", link: "#" },
    ];

    useEffect(() => {
        props.setBreadcrumbItems('Usuários Terra', breadcrumbItems);

    }, []);

    return (
        <React.Fragment>
            <MetaTags>
                <title>Usuários Terra | Grupo Terra</title>
            </MetaTags>

            <Row>
                <CardUser />
            </Row>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(TerraUsers);