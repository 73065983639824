import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import {
  Row,
  Col,
} from "reactstrap";

// Pages Components
import Miniwidget from "./Miniwidget";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";

import { setBreadcrumbItems } from "../../store/actions";
import { get } from "helpers/api_helper";
import { useAuth } from "hooks/useAuth";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";
import isAllowed from "components/Common/IsAllowed";

const Dashboard = (props) => {

  const [KPIs, setKPIs] = useState({});
  const [carts, setCarts] = useState([]);
  const [orders, setOrders] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const getKPIColor = (value) => {

    let filteredValue = (value?.replace("%", ""))?.replace(",", '.');

    var color = "danger";

    if (filteredValue > 0){

      color = "success";

    };

    return color;

  };

  const { userData: {
    orders_permissions: { visualize_orders },
    statistics_permissions: { visualize_statistics },
  } } = useAuth();

  const breadcrumbItems = [
    { title: "Terra", link: "#" },
    { title: "Dashboard", link: "#" }
  ];

  useEffect(() => {

    props.setBreadcrumbItems('Dashboard', breadcrumbItems);

    const fetchData = async () => {

      const response = await get('home_screen_kpis.php');

      if (response.success) {

        isAllowed(visualize_statistics) && setKPIs(response.KPIs);
        isAllowed(visualize_orders) && setOrders(response.last_current_orders);
        isAllowed(visualize_orders) && setCarts(response.last_opened_carts);
        setIsLoading(false);
      };
    };

    fetchData();

  }, []);

  const reports = [
    { title: `Pedidos / ${KPIs.current_month}`, iconClass: "cube-outline", total: KPIs.orders_current_month, average: KPIs.percentage_orders_comparison, badgecolor: getKPIColor(KPIs.percentage_orders_comparison) },
    { title: `Faturamento / ${KPIs.current_month}`, iconClass: "buffer", total: KPIs.billing_current_month, average: KPIs.pertentage_billing_comparison, badgecolor: getKPIColor(KPIs.pertentage_billing_comparison) },
    { title: `Preço Médio por Pedido / ${KPIs.current_month}`, iconClass: "tag-text-outline", total: KPIs.average_price_current_month, average: KPIs.percentage_average_price_comparison, badgecolor: getKPIColor(KPIs.percentage_average_price_comparison) },
    { title: `Produtos Vendidos / ${KPIs.current_month}`, iconClass: "briefcase-check", total: KPIs.sold_products, average: KPIs.percentage_sold_products_comparison, badgecolor: getKPIColor(KPIs.percentage_sold_products_comparison) },
  ];

  return <LoadingHandlerScreen isLoading={isLoading} component={
    <React.Fragment>

      <MetaTags>
        <title>Dashboard - Terra</title>
      </MetaTags>

      {/*mimi widgets */}
      {isAllowed(visualize_statistics) && <Miniwidget reports={reports} />}

      {
        isAllowed(visualize_orders) &&
        <Row>
          <Col xl="12">
            {/* latest transactions */}
            <LatestTransactions carts={carts} />
          </Col>

          <Col xl="12">
            {/* latest orders */}
            <LatestOrders orders={orders} />
          </Col>
        </Row>}

    </React.Fragment>
  } />
}

export default connect(null, { setBreadcrumbItems })(Dashboard);