import React, { useEffect, useRef, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import ReactInputMask from "react-input-mask";
import hasImage from "common/hasImage";
import { get } from "helpers/api_helper";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { useCompanyContext } from "contexts/CompanyContextProvider";

const CreateCustomerCompany = (props) => {

  const { holdingData, updateHoldingData, handleCreateCustomer } = useCompanyContext();

  const [brStates, setBrStates] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [terraSellers, setTerraSellers] = useState([]);

  const [formData, setFormData] = useState({
    cnpj: '',
    fantasy_name: '',
    corporate_reason: '',
    corporate_email: '',
    frete: '',
    unity_modality: '',
    phone: '',
    whatsapp: '',
    payment_method: '',
    cashback_percentage: '',
    image: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    zip_code: '',
    users: []
  });

  const ActionButton = ({ index }) => {
    return (
      <Link to={`/pedidos-detalhes/${index}`}>
        <Button color="primary" size="sm" className="waves-effect waves-light">
          <i className="mdi mdi-magnify"></i> Detalhes
        </Button>
      </Link>
    )
  };

  const CreateUpdateHolding = () => {
    return (
      <Button color={holdingData ? 'info' : 'primary'} size="lg" className="waves-effect waves-light" onClick={() => handleUpdateHolding()}>
        <i className="mdi mdi-magnify"></i>{holdingData ? 'Atualizar Cliente' : 'Iniciar Cadastro'}
      </Button>
    )
  };

  const CreateCustomer = () => {

    if (holdingData) {
      return (
        <Button color={'success'} size="lg" className="waves-effect waves-light" onClick={handleCreateCustomer}>
          <i className="mdi mdi-magnify"></i> Finalizar Cadastro
        </Button>
      );
    };

    return null;

  };

  const fileInputRef = useRef(null);

  const getUF = () => {
    get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
      .then(res => {
        if (res) {
          setBrStates(res);
        }
      })
  };

  const getTerraSellers = () => {
    get('insercao_empresa/list_all_terra_users.php')
      .then(res => {
        if (res) {
          setTerraSellers(res.users);
        };
      });
  };
  const getCompanyData = () => {

    if (holdingData) {
      setFormData(holdingData);
    };

    return null;

  };

  const getPaymentMethods = () => {
    get('get_payment_methods.php')
      .then(res => {
        if (res) {
          setPaymentMethods(res.payment_methods);
        };
      });
  };

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Clientes", link: "/clientes" },
    { title: "Cadastrar cliente", link: "#" },
  ];

  useEffect(() => {
    props.setBreadcrumbItems('Cadastrar Cliente', breadcrumbItems);

    getPaymentMethods();
    getTerraSellers();
    getCompanyData();
    getUF();

  }, [holdingData]);

  useEffect(() => {

    const finalCNPJ = formData.cnpj.replace(/[^\d]/g, '');

    if (finalCNPJ.length === 14) {

      get('https://api-publica.speedio.com.br/buscarcnpj?cnpj=' + finalCNPJ)
        .then(res => {
          const response = res;

          if (response['CNPJ']) {
            setFormData({
              ...formData,
              fantasy_name: response['NOME FANTASIA'] || '',
              corporate_reason: response['RAZAO SOCIAL'] || '',
              corporate_email: response['EMAIL'] || '',
              phone: `(${response['DDD']}) ${response['TELEFONE']}` || '',
              address: response['TIPO LOGRADOURO'] ? `${response['TIPO LOGRADOURO']} ${response['LOGRADOURO']}` : '',
              number: response['NUMERO'] || '',
              complement: response['COMPLEMENTO'] || '',
              neighborhood: response['BAIRRO'] || '',
              city: response['MUNICIPIO'] || '',
              state: response['UF'] || '',
              zip_code: response['CEP'] || ''
            });
          }
        })
    };

  }, [formData.cnpj]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          image: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateHolding = (e) => {

    updateHoldingData(formData);

  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const branchData = {
    columns: [
      {
        label: "Número",
        field: "number",
        sort: "asc",
        width: 50,
      },
      {
        label: "Nome",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Número de Colaboradores",
        field: "employees",
        sort: "asc",
        width: 150,
      },
      {
        label: "Data de Inclusão",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Comprado",
        field: "total",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ações",
        field: "action",
        width: 150,
      },
    ],
    rows: Array(2).fill().map((_, index) => ({
      number: index,
      name: "Filial " + index,
      employees: 2 * index,
      date: index + 1 + "/11/2023",
      total: "R$" + index * 1000 * 1.25,
      action: <ActionButton />,
    }))
  };

  const employeesData = {
    columns: [
      {
        label: "Name",
        field: "full_name",
        sort: "asc",
        width: 250,
      },
      {
        label: "Whatsapp",
        field: "whatsapp",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ações",
        field: "action",
        width: 150,
      },
    ],
    rows: formData.users,
  };

  return (
    <>
      <MetaTags>
        <title>Detalhes de Cliente | Grupo Terra</title>
      </MetaTags>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} sm={6} md={4} lg={3} className="d-flex justify-content-center align-items-center">
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <Button onClick={handleImageClick}>
                    <img
                      className="img-thumbnail"
                      alt="Imagem"
                      width="200"
                      src={formData.image ? formData.image : hasImage()}
                    />
                  </Button>
                </Col>
                <Col xs={12} sm={6} md={8} lg={9}>
                  <div className="mt-5 mt-lg-4">
                    <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informações Principais</h5>

                    <div className="row mb-4">
                      <label htmlFor="horizontal-cnpj-input" className="col-sm-3 col-form-label">CNPJ</label>
                      <div className="col-sm-9">
                        <ReactInputMask
                          mask='99.999.999/9999-99'
                          className="form-control"
                          required
                          name="cnpj"
                          value={formData.cnpj}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Nome Fantasia</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="fantasy_name"
                          name="fantasy_name"
                          value={formData.fantasy_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Razão Social</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="horizontal-firstname-input"
                          name="corporate_reason"
                          value={formData.corporate_reason}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label htmlFor="corporate_email" className="col-sm-3 col-form-label">E-mail Corporativo</label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="corporate_email"
                          name="corporate_email"
                          value={formData.corporate_email}
                          onChange={handleInputChange}  
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col className="col-sm-12 mb-3">
                  <h3>
                    Endereço
                  </h3>
                </Col>

                <Col className="col-sm-4 mb-3">
                  <label htmlFor="address" className="col-form-label">CEP</label>
                  <div className="col-md-10">
                    <ReactInputMask
                      mask='99.999-999'
                      id="zip_code"
                      className="form-control"
                      type="text"
                      name="zip_code"
                      value={formData.zip_code}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="address" className="col-form-label">Endereço</label>
                  <div className="col-md-10">
                    <input
                      id="address"
                      className="form-control"
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="number" className="col-form-label">Número</label>
                  <div className="col-md-10">
                    <input
                      id="number"
                      className="form-control"
                      type="text"
                      name="number"
                      value={formData.number}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="complement" className="col-form-label">Complemento</label>
                  <div className="col-md-10">
                    <input
                      id="complement"
                      className="form-control"
                      type="text"
                      name="complement"
                      value={formData.complement}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="neighborhood" className="col-form-label">Bairro</label>
                  <div className="col-md-10">
                    <input
                      id="neighborhood"
                      className="form-control"
                      type="text"
                      name="neighborhood"
                      value={formData.neighborhood}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="city" className="col-form-label">Cidade</label>
                  <div className="col-md-10">
                    <input
                      id="city"
                      className="form-control"
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="state" className="col-form-label">Estado</label>
                  <div className="col-md-10">
                    <select
                      id="state"
                      className="form-control"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}>
                      <option value="">Selecione um Estado</option>
                      {
                        brStates.map(state => (
                          <option key={state.id} value={state.sigla}>{state.sigla}</option>
                        ))
                      }
                    </select>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col className="col-sm-12 mb-3">
                  <h3>
                    Detalhes do Negócio
                  </h3>
                </Col>

                <Col className="col-sm-4 mb-3">
                  <label htmlFor="modality" className="col-form-label">Modalidade de Unidade</label>
                  <div className="col-md-10">
                    <select
                      id="unity_modality"
                      className="form-control"
                      type="text"
                      name="unity_modality"
                      value={formData.unity_modality}
                      onChange={handleInputChange}>
                      <option value="">Selecione uma Modalidade</option>
                      <option value="1">Holding</option>
                    </select>
                  </div>
                </Col>

                <Col className="col-sm-4 mb-3">
                  <label htmlFor="modality" className="col-form-label">Vendedor Responsável</label>
                  <div className="col-md-10">
                    <select
                      id="seller_id"
                      className="form-control"
                      type="text"
                      name="seller_id"
                      value={formData.seller_id}
                      onChange={handleInputChange}>
                      <option value="">Selecione um Vendedor</option>
                      {
                        terraSellers.map(seller => (
                          <option value={seller.id}>{seller.full_name}</option>
                        ))
                      }
                    </select>
                  </div>
                </Col>

                <Col className="col-sm-4 mb-3">
                  <label htmlFor="phone" className="col-form-label">Telefone</label>
                  <div className="col-md-10">
                    <ReactInputMask
                      mask='(99) 9999-9999'
                      className="form-control"
                      type="tel"
                      required
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="whatsapp" className="col-form-label">Whatsapp</label>
                  <div className="col-md-10">
                    <ReactInputMask
                      mask='(99) 9 9999-9999'
                      className="form-control"
                      type="tel"
                      required
                      name="whatsapp"
                      value={formData.whatsapp}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="paymentMethod" className="col-form-label">Formas de Pagamento</label>
                  <div className="col-md-10">
                    <select
                      id="paymentMethod"
                      className="form-control"
                      type="text"
                      name="payment_method"
                      value={formData.payment_method}
                      onChange={handleInputChange}>
                      <option value="">Selecione uma Forma de Pagamento</option>
                      {
                        paymentMethods?.map(method => (
                          <option key={method.id} value={method.id}>{method.payment_name}</option>
                        ))
                      }
                    </select>
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="cashbackPercentage" className="col-form-label">Porcentagem de Cashback</label>
                  <div className="col-md-10">
                    <input
                      id="cashbackPercentage"
                      className="form-control"
                      type="number"
                      step="0.1"
                      name="cashback_percentage"
                      value={formData.cashback_percentage}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="discountPercentage" className="col-form-label">Porcentagem de Desconto</label>
                  <div className="col-md-10">
                    <input
                      id="discountPercentage"
                      className="form-control"
                      type="number"
                      step="0.1"
                      name="discount_percentage"
                      value={formData.discount_percentage}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
                <Col className="col-sm-4 mb-3">
                  <label htmlFor="discountPercentage" className="col-form-label">Frete</label>
                  <div className="col-md-10">
                    <input
                      id="frete"
                      className="form-control"
                      type="number"
                      name="frete"
                      value={formData.frete}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Col>
            <Card>
              <CardBody>
                <Col className="col-sm-12 mb-3 d-flex justify-content-between align-items-center">
                  <h3>
                    Filiais desta unidade
                  </h3>
                  <Link to="/cadastrar-empresa-cliente">
                    <Button size='lg' className="btn btn-success waves-effect waves-light"><i className='mdi mdi-account-plus'> </i>Cadastrar Filial</Button>
                  </Link>
                </Col>
                <div className="mt-3">
                  <MDBDataTable responsive striped bordered data={branchData} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Col>

        <Col xs={12}>
          <Col >
            <Card>
              <CardBody>
                <Col className="col-sm-12 mb-3 d-flex justify-content-between align-items-center">
                  <h3>
                    Colaboradores desta unidade
                  </h3>
                  <Link to="/cadastrar-colaborador-cliente/">
                    <Button size='lg' className="btn btn-success waves-effect waves-light"><i className='mdi mdi-account-plus'> </i>Cadastrar Colaborador</Button>
                  </Link>
                </Col>
                <Col className="col-sm-12 mb-3">
                  <div className="mt-3">
                    <MDBDataTable responsive striped bordered data={employeesData} />
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Col>
        <Col xs={12}>
          <Col>
            <Card>
              <CardBody>
                <Col>
                  <CreateUpdateHolding /> <CreateCustomer />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Col>
      </Row>
    </>
  )
}

export default connect(null, { setBreadcrumbItems })(CreateCustomerCompany);