import React from "react"
import { Navigate } from "react-router-dom"

///Profile
import UserProfile from "../pages/Authentication/user-profile"

///Pages Calendar
import Calendar from "../pages/Calendar/index"

///Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailCompose from "../pages/Email/email-compose"

///Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

///Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

///Dashboard
import Dashboard from "../pages/Dashboard/index"

///Charts
import ChartsAppex from "../pages/Charts/charts-appex";
import ChartsChartist from "../pages/Charts/charts-chartist";
import ChartsJs from "../pages/Charts/charts-chartjs";
import ChartsKnob from "../pages/Charts/charts-knob";
import ChartsC3 from "../pages/Charts/charts-c3";
import ChartsSparkLine from "../pages/Charts/charts-sparkline";

///Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

///Icons
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import Iconion from "../pages/Icons/Iconion"
import IconFontawesome from "../pages/Icons/IconFontawesome"
import IconThemify from "../pages/Icons/IconThemify"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconTypicons from "../pages/Icons/IconTypicons"

///Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

///Forms
import FormElements from "../pages/Forms/FormElements"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormUpload from "../pages/Forms/FormUpload"
import FormXeditable from "../pages/Forms/FormXeditable"

///Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiBadge from "../pages/Ui/UiBadge"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiPagination from "../pages/Ui/UiPagination"
import UiPopoverTooltips from "../pages/Ui/UiPopoverTooltips"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiSweetAlert from "../pages/Ui/UiSweetAlert"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"

///Extra Pages
import PagesInvoice from "../pages/Extra Pages/pages-invoice";
import PagesDirectory from "../pages/Extra Pages/pages-directory";
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";
import Orders from "pages/Tables/Orders"
import TerraUsers from "pages/Extra Pages/TerraUsers"
import Products from "pages/Tables/Products"
import ShoppingCarts from "pages/Tables/ShoppingCarts"
import Customers from "pages/Extra Pages/Customers"
import OrderDetails from "pages/Extra Pages/OrderDetails"
import CreateTerraUser from "pages/Forms/CreateTerraUser"
import ShoppingCartDetails from "pages/Extra Pages/ShoppingCartDetails"
import UpdateTerraUser from "pages/Forms/UpdateTerraUser"
import DetailsTerraUser from "pages/Forms/DetailsTerraUser"
import CreateCustomerCompany from "pages/Forms/CreateCustomerCompany"
import CreateCustomerEmployee from "pages/Forms/CreateCustomerEmployee"
import CreateCustomerBranch from "pages/Forms/CreateCustomerBranch"
import CustomerDetails from "pages/Forms/CustomerDetails"
import OrdersReport from "pages/Tables/OrdersReport"
import ShoppingCartReport from "pages/Tables/ShoppingCartReport"
import UsersReport from "pages/Tables/SellerSalesReport"
import Profile from "pages/Forms/Profile"
import ProductDetails from "pages/Forms/FormWizardSteps/ProductDetails"
import ProductEdit from "pages/Forms/FormWizardSteps/ProductEdit"
import CustomerEdit from "pages/Forms/CustomerEdit"
import OrdersEdit from "pages/Extra Pages/OrdersEdit"
import UpdateCustomerEmployee from "pages/Forms/UpdateCustomerEmployee"
import MatrixEdit from "pages/Forms/MatrixEdit"
import BranchEdit from "pages/Forms/BranchEdit"
import MatrixDetails from "pages/Forms/MatrixDetails"
import BranchDetails from "pages/Forms/BranchDetails"
import CreateMatrixEmployee from "pages/Forms/CreateMatrixEmployee"
import CreateBranchEmployee from "pages/Forms/CreateBranchEmployee"
import CustomerEmployeeDetails from "pages/Forms/CustomerEmployeeDetails"
import SellerSalesReport from "pages/Tables/SellerSalesReport"
import BuyersList from "pages/Tables/BuyersList"

const userRoutes = [
  {
    path: "/",
    exact: true,
    logged_component: Dashboard,
    default_component: Login
  },
  {
    path: "/dashboard",
    logged_component: Dashboard,
    default_component: Login
  },
  {
    path: "/perfil",
    logged_component: Profile,
    default_component: Login
  },
  {
    path: "/pedidos",
    logged_component: Orders,
    default_component: Login
  },
  {
    path: "/pedido-editar/:id",
    logged_component: OrdersEdit,
    default_component: Login
  },
  {
    path: "/pedido-detalhes/:id",
    logged_component: OrderDetails,
    default_component: Login
  },
  {
    path: "/produtos",
    logged_component: Products,
    default_component: Login
  },
  {
    path: "/produto-detalhes/:id",
    logged_component: ProductDetails,
    default_component: Login
  },
  {
    path: "/produto-editar/:id",
    logged_component: ProductEdit,
    default_component: Login
  },
  {
    path: "/carrinhos",
    logged_component: ShoppingCarts,
    default_component: Login
  },
  {
    path: "/compradores",
    logged_component: BuyersList,
    default_component: Login
  },
  {
    path: "/carrinhos-detalhes/:id",
    logged_component: ShoppingCartDetails,
    default_component: Login
  },
  {
    path: "/relatorios-de-pedidos",
    logged_component: OrdersReport,
    default_component: Login
  },
  {
    path: "/relatorios-de-carrinhos",
    logged_component: ShoppingCartReport,
    default_component: Login
  },
  {
    path: "/relatorios-de-vendas-vendedor",
    logged_component: SellerSalesReport,
    default_component: Login
  },
  {
    path: "/criar-usuario-terra",
    logged_component: CreateTerraUser,
    default_component: Login
  },
  {
    path: "/usuarios-terra",
    logged_component: TerraUsers,
    default_component: Login
  },
  {
    path: "/atualizar-usuario-terra/:id",
    logged_component: UpdateTerraUser,
    default_component: Login
  },
  {
    path: "/detalhes-usuario-terra/:id",
    logged_component: DetailsTerraUser,
    default_component: Login
  },
  {
    path: "/clientes",
    logged_component: Customers,
    default_component: Login
  },

  // CRUD - Holding:
  {
    path: "/clientes-detalhes/:id",
    logged_component: CustomerDetails,
    default_component: Login
  },
  {
    path: "/cliente-editar/:id",
    logged_component: CustomerEdit,
    default_component: Login
  },

  // CRUD - Matriz:
  {
    path: "/matriz-clientes-detalhes/:id",
    logged_component: MatrixDetails,
    default_component: Login
  },
  {
    path: "/matriz-cliente-editar/:id",
    logged_component: MatrixEdit,
    default_component: Login
  },

  // CRUD - Filial:
  {
    path: "/filial-clientes-detalhes/:id",
    logged_component: BranchDetails,
    default_component: Login
  },
  {
    path: "/filial-cliente-editar/:id",
    logged_component: BranchEdit,
    default_component: Login
  },

  // CRUD - Colaborador:
  {
    path: "/cadastrar-colaborador-cliente/:id",
    logged_component: CreateCustomerEmployee,
    default_component: Login
  },
  {
    path: "/matriz-cadastrar-colaborador-cliente/:id",
    logged_component: CreateMatrixEmployee,
    default_component: Login
  },
  {
    path: "/filial-cadastrar-colaborador-cliente/:id",
    logged_component: CreateBranchEmployee,
    default_component: Login
  },
  {
    path: "/colaborador-editar/:id",
    logged_component: UpdateCustomerEmployee,
    default_component: Login
  },
  {
    path: "/colaborador-detalhes/:id",
    logged_component: CustomerEmployeeDetails,
    default_component: Login
  },
  {
    path: "/login",
    logged_component: Dashboard,
    default_component: Login
  },
  {
    path: "/forgot-password",
    logged_component: Dashboard,
    default_component: ForgetPwd
  },
  {
    path: "/pages-404",
    logged_component: Pages404,
    default_component: Pages404
  },
  {
    path: "/pages-500",
    logged_component: Pages500,
    default_component: Pages500
  },
  {
    path: "*",
    logged_component: Dashboard,
    default_component: Login
  },
  {
    path: "/icons-materialdesign",
    logged_component: IconMaterialdesign,
    default_component: Login
  },

  ///calendar
  // { path: "/calendar", component: Calendar },
  ///Email
  // { path: "/email-inbox", component: EmailInbox },
  // { path: "/email-read", component: EmailRead },
  // { path: "/email-compose", component: EmailCompose },

  ///Charts
  // { path: "/apex-charts", component: ChartsAppex },
  // { path: "/charts-chartist", component: ChartsChartist },
  // { path: "/charts-chartjs", component: ChartsJs },
  // { path: "/charts-knob", component: ChartsKnob },
  // { path: "/charts-c3", component: ChartsC3 },
  // { path: "/sparkline-charts", component: ChartsSparkLine },

  ///Icons
  // { path: "/icons-ion", component: Iconion },
  // { path: "/icons-fontawesome", component: IconFontawesome },
  // { path: "/icons-themify", component: IconThemify },
  // { path: "/icons-dripicons", component: IconDripicons },
  // { path: "/icons-typicons", component: IconTypicons },

  ///Tables
  // { path: "/tables-basic", component: BasicTables },
  // { path: "/tables-datatable", component: DatatableTables },
  // { path: "/tables-responsive", component: ResponsiveTables },
  // { path: "/tables-editable", component: EditableTables },
  ///Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },
  // { path: "/maps-leaflet", component: MapsLeaflet },

  ///Forms
  // { path: "/form-elements", component: FormElements },
  // { path: "/form-advanced", component: FormAdvanced },
  // { path: "/form-editors", component: FormEditors },
  // { path: "/form-uploads", component: FormUpload },
  // { path: "/form-validation", component: FormValidations },
  // { path: "/form-xeditable", component: FormXeditable },
  ///Ui
  // { path: "/ui-alerts", component: UiAlert },
  // { path: "/ui-buttons", component: UiButtons },
  // { path: "/ui-badge", component: UiBadge },
  // { path: "/ui-cards", component: UiCards },
  // { path: "/ui-carousel", component: UiCarousel },
  // { path: "/ui-dropdowns", component: UiDropdown },
  // { path: "/ui-grid", component: UiGrid },
  // { path: "/ui-images", component: UiImages },
  // { path: "/ui-lightbox", component: UiLightbox },
  // { path: "/ui-modals", component: UiModal },
  // { path: "/ui-pagination", component: UiPagination },
  // { path: "/ui-popover-tooltip", component: UiPopoverTooltips },
  // { path: "/ui-progressbars", component: UiProgressbar },
  // { path: "/ui-sweet-alert", component: UiSweetAlert },
  // { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  // { path: "/ui-typography", component: UiTypography },
  // { path: "/ui-video", component: UiVideo },
  // { path: "/ui-session-timeout", component: UiSessionTimeout },
  // { path: "/ui-rangeslider", component: UiRangeSlider },
  ///Extra Pages
  // { path: "/pages-timeline", component: PagesTimeline },
  // { path: "/pages-invoice", component: PagesInvoice },
  // { path: "/pages-directory", component: PagesDirectory },
  // { path: "/pages-blank", component: PagesBlank },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  ///Authentication Inner
  // { path: "/pages-login", component: Login1 },
  // { path: "/pages-register", component: Register1 },
  // { path: "/page-recoverpw", component: Recoverpw },
  // { path: "/auth-lock-screen", component: LockScreen },
  ///profile
];

export { userRoutes };