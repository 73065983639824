import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import {
    Card,
    CardBody,
    Col,
    Row,
    CardTitle,
} from "reactstrap"

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import ReactInputMask from "react-input-mask";
import { get } from "helpers/api_helper";
import hasImage from "common/hasImage";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";
import { useAuth } from "hooks/useAuth";

const Profile = (props) => {

    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [brStates, setBrStates] = useState([]);

    const { userData: { id_admin } } = useAuth();

    console.log(id_admin)

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Usuários Terra", link: "/usuarios-terra" },
        { title: "Detalhes de Usuário Terra", link: "#" }
    ];

    const getUserData = () => {
        get('user_data.php?id=' + id_admin)
            .then(res => {
                if (res) {
                    setFormData(res.data);
                    setIsLoading(false);
                };
            })
    };

    const getUF = () => {
        get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
            .then(res => {
                if (res) {
                    setBrStates(res);
                }
            })
    };

    useEffect(() => {

        props.setBreadcrumbItems('Detalhes de Usuário Terra', breadcrumbItems);

        getUserData();
        getUF();

    }, []);

    return <LoadingHandlerScreen isLoading={isLoading} component={
        <React.Fragment>

            <MetaTags>
                <title>Detalhes de Usuário Terra | Grupo Terra</title>
            </MetaTags>

            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs={12} sm={6} md={4} lg={3} className="d-flex justify-content-center align-items-center">
                                    <img
                                        className="img-thumbnail"
                                        alt="Imagem"
                                        width="200"
                                        src={hasImage(formData?.image)}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={8} lg={9}>
                                    <div className="mt-5 mt-lg-4">
                                        <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informações Principais</h5>

                                        <form>
                                            <div className="row mb-4">
                                                <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Nome Completo</label>
                                                <div className="col-sm-9">
                                                    <input disabled type="text" className="form-control" id="horizontal-firstname-input" name="full_name" value={formData?.full_name} />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <label htmlFor="horizontal-cpf-input" className="col-sm-3 col-form-label">CPF</label>
                                                <div className="col-sm-9">
                                                    <ReactInputMask
                                                        disabled
                                                        mask='999.999.999-99'
                                                        className="form-control"
                                                        type="tel"
                                                        id="cpf"
                                                        placeholder="Insira o CPF do colaborador *"
                                                        name="cpf" value={formData?.cpf}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">Whatsapp</label>
                                                <div className="col-sm-9">
                                                    <ReactInputMask
                                                        disabled
                                                        mask='(99) 9 9999-9999'
                                                        className="form-control"
                                                        type="tel"
                                                        id="whatsapp"
                                                        placeholder="Insira o Whatsapp do colaborador *"
                                                        name="whatsapp" value={formData?.whatsapp}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="h4 mb-4">Contato Informações Importantes</CardTitle>
                            <Row className="mb-3">
                                <label
                                    htmlFor="phone"
                                    className="col-md-2 col-form-label"
                                >
                                    Telefone
                                </label>
                                <div className="col-md-10">
                                    <ReactInputMask
                                        disabled
                                        mask='(99) 9999-9999'
                                        className="form-control"
                                        type="tel"
                                        id="phone"
                                        placeholder="Insira um telefone do colaborador *"
                                        name="cellphone" value={formData?.cellphone}
                                        required
                                    />
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <label htmlFor="cargo" className="col-md-2 col-form-label">
                                    Função
                                </label>
                                <div className="col-md-10">
                                    <select disabled className="form-control" id="funcao" name="function_id" value={formData?.function_id} >
                                        <option value={1}>Direção</option>
                                    </select>
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <label htmlFor="horizontal-email-input" className="col-sm-2 col-form-label">E-mail</label>
                                <div className="col-sm-10">
                                    <input disabled type="email" className="form-control" id="horizontal-email-input" name="email" value={formData?.email} />
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="h4 mb-4">Endereço</CardTitle>
                            <Row>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="address" className="col-form-label">CEP</label>
                                    <div className="col-md-10">
                                        <ReactInputMask
                                            mask='99.999-999'
                                            id="zip_code"
                                            className="form-control"
                                            type="text"
                                            name="zip_code"
                                            value={formData?.zip_code}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="address" className="col-form-label">Endereço</label>
                                    <div className="col-md-10">
                                        <input
                                            id="address"
                                            className="form-control"
                                            type="text"
                                            name="address"
                                            value={formData?.address}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="number" className="col-form-label">Número</label>
                                    <div className="col-md-10">
                                        <input
                                            id="number"
                                            className="form-control"
                                            type="text"
                                            name="number"
                                            value={formData?.number}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="complement" className="col-form-label">Complemento</label>
                                    <div className="col-md-10">
                                        <input
                                            id="complement"
                                            className="form-control"
                                            type="text"
                                            name="complement"
                                            value={formData?.complement}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="neighborhood" className="col-form-label">Bairro</label>
                                    <div className="col-md-10">
                                        <input
                                            id="neighborhood"
                                            className="form-control"
                                            type="text"
                                            name="neighborhood"
                                            value={formData?.neighborhood}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="city" className="col-form-label">Cidade</label>
                                    <div className="col-md-10">
                                        <input
                                            id="city"
                                            className="form-control"
                                            type="text"
                                            name="city"
                                            value={formData?.city}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="state" className="col-form-label">Estado</label>
                                    <div className="col-md-10">
                                        <select
                                            id="state"
                                            className="form-control"
                                            name="state"
                                            value={formData?.state}
                                            disabled
                                        >
                                            <option value="">Selecione um Estado</option>
                                            {
                                                brStates.map(state => (
                                                    <option key={state.id} value={state.sigla}>{state.sigla}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    } />
}

export default connect(null, { setBreadcrumbItems })(Profile);