import React, { useEffect } from 'react';
import { Row } from "reactstrap";

import MetaTags from 'react-meta-tags';

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

//Import Components
import CardUser from "./card-user";

//Import Images
import CardCustomers from './card-customers';

const user2 = "https://img.freepik.com/vetores-gratis/modelo-de-logotipo-de-amigos_23-2149505594.jpg?w=740&t=st=1699566183~exp=1699566783~hmac=ca55f1ed768d5e5cda5a34de30e543d10a224d8567efa92d264f1010ffa62218";
const user3 = user2;
const user4 = user2;
const user5 = user2;
const user6 = user2;
const user7 = user2;

const Customers = (props) => {
    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Clientes", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Clientes', breadcrumbItems);
        
    }, [])

    return (
        <React.Fragment>
            <MetaTags>
                <title>Clientes | Grupo Terra</title>
            </MetaTags>

            <Row>
                <CardCustomers />
            </Row>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(Customers);