import React, { useEffect, useRef, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import ReactInputMask from "react-input-mask";
import hasImage from "common/hasImage";
import { get, post } from "helpers/api_helper";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import makeAnimated from "react-select/animated";
import ReactSelect from "react-select";
import { useAuth } from "hooks/useAuth";
import SweetAlert from "react-bootstrap-sweetalert";


const CustomerEdit = (props) => {

    const { id } = useParams();

    const [brStates, setBrStates] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [terraSellers, setTerraSellers] = useState([]);
    const [imageLoading, setImageLoading] = useState(false);

    const [selectedHolding, setSelectedHolding] = useState(false);

    const [holdingList, setHoldingList] = useState([{}]);

    const [matrix, setMatrix] = useState([]);
    const [removeMatrix, setRemoveMatrix] = useState(false);
    const [removeMatrixId, setRemoveMatrixId] = useState(null);

    const [removeUser, setRemoveUser] = useState(false);
    const [removeUserId, setRemoveUserId] = useState(null);

    const [users, setUsers] = useState([]);

    const navigate = useNavigate();

    const { userData: { id_admin } } = useAuth();

    const fileInputRef = useRef(null);

    const animatedComponents = makeAnimated();

    const [formData, setFormData] = useState({
        cnpj: '',
        fantasy_name: '',
        corporate_reason: '',
        corporate_email: '',
        frete: '',
        phone: '',
        whatsapp: '',
        payment_methods: '',
        cashback_percentage: '',
        image: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        zip_code: '',
    });

    const getUF = () => {
        get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
            .then(res => {
                if (res) {
                    setBrStates(res);
                }
            })

    };

    const getTerraSellers = () => {
        get('insercao_empresa/list_all_terra_users.php')
            .then(res => {
                if (res.users) {
                    setTerraSellers(res.users);
                };
            });
    };

    const getCompanyData = () => {

        get('list_companies/Detalhes/holdings_details.php?id=' + id)
            .then(res => {
                if (res) {

                    const holding_users = res.holdings.users.map(data => {
                        return {
                            ...data,
                            image: <img alt={data.full_name} className="avatar-xs me-2 rounded-circle" src={hasImage(data.image)} />,
                            action: <ActionButton id={data.id} />
                        }
                    });


                    const holding_matrix = res.holdings.matrixes?.map(data => {
                        return {
                            ...data,
                            image: <img alt={data.name} className="avatar-xs me-2 rounded-circle" src={hasImage(data.image)} />,
                            action: <ActionButtonMatrix id={data.company_id} />
                        }
                    });

                    setUsers(holding_users);
                    setMatrix(holding_matrix);
                    setFormData(res.holdings);
                };
            });

    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const getPaymentMethods = () => {
        get('get_payment_methods.php')
            .then(res => {
                if (res) {
                    setPaymentMethods(res.payment_methods);
                };
            });
    };

    const validateFormData = () => {
        const requiredFields = [
            'cnpj',
            'fantasy_name',
            'corporate_reason',
            'corporate_email',
            'frete',
            'unity_modality',
            'phone',
            'whatsapp',
            'payment_methods',
            'cashback_percentage',
            'address',
            'number',
            'neighborhood',
            'city',
            'state',
            'zip_code',
        ];

        for (const field of requiredFields) {
            if (!formData[field]) {
                return false;
            };
        };

        return true;
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageLoading(true); // Indica que a imagem está sendo carregada
            const reader = new FileReader();
            reader.onloadend = () => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const aspectRatio = img.width / img.height;
                    let newWidth, newHeight;

                    // Definir dimensões de corte mantendo a proporção 1:1 (500x500)
                    if (aspectRatio > 1) {
                        newWidth = 500;
                        newHeight = 500 / aspectRatio;
                    } else {
                        newWidth = 500 * aspectRatio;
                        newHeight = 500;
                    };

                    // Centralizar a imagem no canvas
                    const offsetX = (500 - newWidth) / 2;
                    const offsetY = (500 - newHeight) / 2;

                    // Desenhar a imagem cortada no canvas
                    canvas.width = 500;
                    canvas.height = 500;
                    ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);

                    // Converter o canvas de volta para o formato base64
                    const croppedImage = canvas.toDataURL('image/jpeg');

                    setFormData({
                        ...formData,
                        image: croppedImage,
                    });

                    setImageLoading(false); // Indica que a imagem foi completamente carregada e processada
                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = () => {
        if (validateFormData()) {

            const payload = {
                ...formData, id_holding: id,
            };

            post('/insercao_empresa/update_holding.php', payload)
                .then(res => {
                    if (res.success) {
                        toast.success('Cliente atualizado com sucesso!');
                        navigate(`/clientes-detalhes/${id}`);
                    } else {
                        toast.error('Houve um erro ao editar este cliente!')
                    };
                })
                .catch(err => {
                    console.error(err);
                    toast.error('Erro de conexão! Tente novamente mais tarde.')
                })
        } else {
            toast.error('Por favor, preencha todos os campos obrigatórios!');
        };
    };

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Clientes", link: "/clientes" },
        { title: "Edição de Cliente ", link: "#" },
    ];

    const getAllHoldings = async () => {
        const payload = { id_admin_user: id_admin };

        const response = await get('list_companies/Listagem/list_all_holdings.php', payload);

        if (response.success) {
            setHoldingList([{
                label: "Lista de Empresas",
                options: response.holdings.map(obj => {
                    return { label: obj.fantasy_name, value: obj.id }
                })
            }]);
        };
    };

    const handleInsertMatrix = async () => {
        const payload = {
            id_holding: id,
            id_matrix: selectedHolding.value
        };

        const response = await post('insercao_empresa/change_company_type/holding_to_matrix.php', payload);

        if (response.success) {
            toast.success('Inclusão de matriz realizada com sucesso!');
            getCompanyData();
            getAllHoldings();
        } else {
            toast.error('Erro ao incluir essa matriz!');
        };
    };

    const ActionButtonMatrix = ({ id }) => {
        return (
            <>
                <Link to={`/matriz-clientes-detalhes/${id}`}>
                    <Button color="primary" size="sm" className="waves-effect waves-light">
                        <i className="mdi mdi-magnify"></i>
                    </Button>
                </Link>
                {" "}
                <Link to={`/matriz-cliente-editar/${id}`}>
                    <Button color="primary" size="sm" className="waves-effect waves-light">
                        <i className="mdi mdi-pencil"></i>
                    </Button>
                </Link>
                {" "}
                <Button color="danger" size="sm" className="waves-effect waves-light" onClick={() => {
                    setRemoveMatrix(true), setRemoveMatrixId(id);
                }}>
                    <i className="mdi mdi-trash-can"></i>
                </Button>
            </>
        )
    };

    const ActionButton = ({ id }) => {
        return (
            <>
                <Link to={`/colaborador-detalhes/${id}`}>
                    <Button color="primary" size="sm" className="waves-effect waves-light">
                        <i className="mdi mdi-magnify"></i>
                    </Button>
                </Link>
                {" "}
                <Link to={`/colaborador-editar/${id}`}>
                    <Button color="primary" size="sm" className="waves-effect waves-light">
                        <i className="mdi mdi-pencil"></i>
                    </Button>
                </Link>
                {" "}
                <Button color="danger" size="sm" className="waves-effect waves-light" onClick={() => {
                    setRemoveUser(true), setRemoveUserId(id);
                }}>
                    <i className="mdi mdi-trash-can"></i>
                </Button>
            </>
        )
    };

    useEffect(() => {
        props.setBreadcrumbItems('Edição de Cliente ', breadcrumbItems);

        getAllHoldings();
        getPaymentMethods();
        getTerraSellers();
        getCompanyData();
        getUF();

    }, []);

    const matrixData = {
        columns: [
            {
                label: "Imagem",
                field: "image",
                sort: "asc",
                width: 50,
            },
            {
                label: "Nome",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "CNPJ",
                field: "cnpj",
                sort: "asc",
                width: 150,
            },
            {
                label: "Vendedor Responsável",
                field: "seller_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Ações",
                field: "action",
                width: 150,
            },
        ],
        rows: matrix
    };

    const employeesData = {
        columns: [
            {
                label: "Imagem",
                field: "image",
                sort: "asc",
                width: 50,
            },
            {
                label: "Name",
                field: "full_name",
                sort: "asc",
                width: 300,
            },
            {
                label: "Whatsapp",
                field: "whatsapp",
                sort: "asc",
                width: 300,
            },
            {
                label: "Ações",
                field: "action",
                width: 150,
            },
        ],
        rows: users,
    };

    const handleImageClick = () => {

        fileInputRef.current.click();

    };

    const handleMatrixRemove = () => {

        const payload = {
            "id_holding": removeMatrixId
        };

        post("/insercao_empresa/change_company_type/matrix_to_holding.php", payload)
            .then(res => {
                if (res.success) {
                    setRemoveMatrix(false);
                    setRemoveMatrixId(null);
                    getCompanyData();
                } else {
                    setRemoveMatrix(false);
                    setRemoveMatrixId(null);
                    toast.error('Houve um erro ao remover essa matrix!')
                };
            })
            .catch(err => {
                toast.error('Houve um erro ao remover essa matrix!')
                console.error(err);
                setRemoveMatrix(false);
                setRemoveMatrixId(null);
            });
    };

    const handleUserDelete = () => {

        const payload = {
            "id_user": removeUserId
        };

        post("user_buyer_delete.php", payload)
            .then(res => {
                if (res.success) {
                    setRemoveUser(false);
                    setRemoveUserId(null);
                    getCompanyData();
                } else {
                    setRemoveUser(false);
                    setRemoveUserId(null);
                    toast.error('Houve um erro ao remover esse usuário!')
                };
            })
            .catch(err => {
                toast.error('Houve um erro ao remover esse usuário!')
                console.error(err);
                setRemoveUser(false);
                setRemoveUserId(null);
            });
    }

    useEffect(() => {

        const finalCNPJ = formData.cnpj.replace(/[^\d]/g, '');

        if (finalCNPJ.length === 14) {

            get('https://api-publica.speedio.com.br/buscarcnpj?cnpj=' + finalCNPJ)
                .then(res => {
                    const response = res;

                    if (response['CNPJ']) {
                        setFormData({
                            ...formData,
                            fantasy_name: response['NOME FANTASIA'] || '',
                            corporate_reason: response['RAZAO SOCIAL'] || '',
                            corporate_email: response['EMAIL'] || '',
                            phone: `(${response['DDD']}) ${response['TELEFONE']}` || '',
                            address: response['TIPO LOGRADOURO'] ? `${response['TIPO LOGRADOURO']} ${response['LOGRADOURO']}` : '',
                            number: response['NUMERO'] || '',
                            complement: response['COMPLEMENTO'] || '',
                            neighborhood: response['BAIRRO'] || '',
                            city: response['MUNICIPIO'] || '',
                            state: response['UF'] || '',
                            zip_code: response['CEP'] || ''
                        });
                    }
                })
        };

    }, [formData.cnpj]);

    const CreateEmployeeButton = () => (
        <Link to={`/cadastrar-colaborador-cliente/${id}`}>
            <Button size='lg' className="btn btn-success waves-effect waves-light"><i className='mdi mdi-account-plus'> </i>Adicionar colaborador</Button>
        </Link>
    );


    return (
        <>
            <MetaTags>
                <title>Edição de Cliente  | Grupo Terra</title>
            </MetaTags>

            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs={12} sm={6} md={4} lg={3} className="d-flex justify-content-center align-items-center">
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                    />
                                    <Button onClick={handleImageClick}>
                                        {imageLoading ? "Carregando..." : (
                                            <img
                                                className="img-thumbnail"
                                                alt="Imagem"
                                                width="200"
                                                src={hasImage(formData.image)}
                                            />
                                        )}
                                    </Button>
                                </Col>
                                <Col xs={12} sm={6} md={8} lg={9}>
                                    <div className="mt-5 mt-lg-4">
                                        <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informações Principais</h5>

                                        <div className="row mb-4">
                                            <label htmlFor="horizontal-cnpj-input" className="col-sm-3 col-form-label">CNPJ</label>
                                            <div className="col-sm-9">
                                                <ReactInputMask
                                                    mask='99.999.999/9999-99'
                                                    className="form-control"
                                                    required
                                                    name="cnpj"
                                                    value={formData.cnpj}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Nome Fantasia</label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="fantasy_name"
                                                    name="fantasy_name"
                                                    value={formData.fantasy_name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="horizontal-firstname-input" className="col-sm-3 col-form-label">Razão Social</label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="horizontal-firstname-input"
                                                    name="corporate_reason"
                                                    value={formData.corporate_reason}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="corporate_email" className="col-sm-3 col-form-label">E-mail Corporativo</label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="corporate_email"
                                                    name="corporate_email"
                                                    value={formData.corporate_email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col className="col-sm-12 mb-3">
                                    <h3>
                                        Endereço
                                    </h3>
                                </Col>

                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="address" className="col-form-label">CEP</label>
                                    <div className="col-md-10">
                                        <ReactInputMask
                                            mask='99.999-999'
                                            id="zip_code"
                                            className="form-control"
                                            type="text"
                                            name="zip_code"
                                            value={formData.zip_code}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="address" className="col-form-label">Endereço</label>
                                    <div className="col-md-10">
                                        <input
                                            id="address"
                                            className="form-control"
                                            type="text"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="number" className="col-form-label">Número</label>
                                    <div className="col-md-10">
                                        <input
                                            id="number"
                                            className="form-control"
                                            type="text"
                                            name="number"
                                            value={formData.number}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="complement" className="col-form-label">Complemento</label>
                                    <div className="col-md-10">
                                        <input
                                            id="complement"
                                            className="form-control"
                                            type="text"
                                            name="complement"
                                            value={formData.complement}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="neighborhood" className="col-form-label">Bairro</label>
                                    <div className="col-md-10">
                                        <input
                                            id="neighborhood"
                                            className="form-control"
                                            type="text"
                                            name="neighborhood"
                                            value={formData.neighborhood}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="city" className="col-form-label">Cidade</label>
                                    <div className="col-md-10">
                                        <input
                                            id="city"
                                            className="form-control"
                                            type="text"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="state" className="col-form-label">Estado</label>
                                    <div className="col-md-10">
                                        <select
                                            id="state"
                                            className="form-control"
                                            name="state"
                                            value={formData.state}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Selecione um Estado</option>
                                            {
                                                brStates.map(state => (
                                                    <option key={state.id} value={state.sigla}>{state.sigla}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <Row>
                                <Col className="col-sm-12 mb-3">
                                    <h3>
                                        Detalhes do Negócio
                                    </h3>
                                </Col>

                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="modality" className="col-form-label">Modalidade de Unidade</label>
                                    <div className="col-md-10">
                                        <select
                                            id="unity_modality"
                                            className="form-control"
                                            type="text"
                                            name="unity_modality"
                                            value={formData.unity_modality}
                                            onChange={handleInputChange}
                                            disabled
                                        >
                                            <option value="1" selected={true}>Holding</option>
                                        </select>
                                    </div>
                                </Col>

                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="modality" className="col-form-label">Vendedor Responsável</label>
                                    <div className="col-md-10">
                                        <select
                                            id="seller_id"
                                            className="form-control"
                                            type="text"
                                            name="seller_id"
                                            value={formData.seller_id}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Selecione um Vendedor</option>
                                            {
                                                terraSellers.map(seller => (
                                                    <option value={seller.id}>{seller.full_name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>

                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="phone" className="col-form-label">Telefone</label>
                                    <div className="col-md-10">
                                        <ReactInputMask
                                            mask='(99) 9 9999-9999'
                                            className="form-control"
                                            type="tel"
                                            required
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="whatsapp" className="col-form-label">Whatsapp</label>
                                    <div className="col-md-10">
                                        <ReactInputMask
                                            mask='(99) 9 9999-9999'
                                            className="form-control"
                                            type="tel"
                                            required
                                            name="whatsapp"
                                            value={formData.whatsapp}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="paymentMethod" className="col-form-label">Formas de Pagamento</label>
                                    <div className="col-md-10">
                                        <select
                                            id="paymentMethod"
                                            className="form-control"
                                            type="text"
                                            name="payment_methods"
                                            value={formData.payment_methods}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Selecione uma Forma de Pagamento</option>
                                            {
                                                paymentMethods?.map(method => (
                                                    <option key={method.id} value={method.id}>{method.payment_name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="cashbackPercentage" className="col-form-label">Porcentagem de Cashback</label>
                                    <div className="col-md-10">
                                        <input
                                            id="cashbackPercentage"
                                            className="form-control"
                                            type="number"
                                            step="0.1"
                                            name="cashback_percentage"
                                            value={formData.cashback_percentage}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="discountPercentage" className="col-form-label">Porcentagem de Desconto</label>
                                    <div className="col-md-10">
                                        <input
                                            id="discountPercentage"
                                            className="form-control"
                                            type="number"
                                            step="0.1"
                                            name="discount"
                                            value={formData.discount}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-sm-4 mb-3">
                                    <label htmlFor="discountPercentage" className="col-form-label">Frete</label>
                                    <div className="col-md-10">
                                        <input
                                            id="frete"
                                            className="form-control"
                                            type="number"
                                            name="frete"
                                            value={formData.frete}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <Col className="col-sm-12 mb-3 d-flex justify-content-between align-items-center">
                                    <h3>
                                        Filiais desta unidade
                                    </h3>
                                </Col>

                                <Row>
                                    <Col xs={8}>
                                        <ReactSelect
                                            options={holdingList}
                                            classNamePrefix="select2-selection"
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            placeholder="Selecione uma empresa para adicionar"
                                            value={selectedHolding}
                                            onChange={selectedOption => setSelectedHolding(selectedOption)}
                                        />
                                    </Col>
                                    <Col xs={4}>
                                        <Button className="w-100" color="primary" onClick={handleInsertMatrix}>
                                            <i className="mdi mdi-domain-plus"></i> Confirmar inclusão
                                        </Button>
                                    </Col>
                                </Row>
                                <div className="mt-3">
                                    <MDBDataTable infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Pesquisar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive bordered data={matrixData} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Col>

                <Col xs={12}>
                    <Col >
                        <Card>
                            <CardBody>
                                <Col className="col-sm-12 mb-3 d-flex justify-content-between align-items-center">
                                    <h3>
                                        Colaboradores desta unidade
                                    </h3>
                                </Col>
                                <CreateEmployeeButton />
                                <Col className="col-sm-12 mb-3">
                                    <div className="mt-3">
                                        <MDBDataTable infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Pesquisar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive bordered data={employeesData} />
                                    </div>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Col>

                <Col xs={12}>
                    <Card>
                        <Button color="primary" onClick={handleSubmit}>
                            <i className="mdi mdi-pencil"></i> Atualizar cliente
                        </Button>
                    </Card>
                </Col>
            </Row>
            {removeMatrix ? (
                <SweetAlert
                    title="Você tem certeza que deseja remover essa unidade?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => handleMatrixRemove()
                    }
                    onCancel={() => setRemoveMatrix(false)
                    }
                >
                </SweetAlert>
            ) : null}
            {removeUser ? (
                <SweetAlert
                    title="Você tem certeza que deseja remover esse usuário?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => handleUserDelete()
                    }
                    onCancel={() => setRemoveUser(false)
                    }
                >
                </SweetAlert>
            ) : null}
        </>
    )
}

export default connect(null, { setBreadcrumbItems })(CustomerEdit);