import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { useParams } from "react-router-dom";
import ProductImages from "pages/Ui/CarouselTypes/ProductImages";

import { useAuth } from "hooks/useAuth";
import { get, post } from "helpers/api_helper";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";
import ReactInputMask from "react-input-mask";

const ProductDetails = (props) => {

    const { id } = useParams();
    const { userData: { id_admin } } = useAuth();

    const [formData, setFormData] = useState({});
    const [categories, setCategories] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Clientes", link: "/clientes" },
        { title: "Detalhes de Produto", link: "#" },
    ];

    const getProductCategories = async () => {

        const response = await get('product_categories.php');

        if (response.success) {
            setCategories(response.categories);
        };
    };

    useEffect(() => {
        props.setBreadcrumbItems('Detalhes de Produto', breadcrumbItems);

        const fetchData = async () => {
            const payload = { id_admin_user: id_admin, product_id: id };
            const response = await post('product_details.php', payload);

            if (response.success) {

                const { product } = response;

                setFormData(product);
                setIsLoading(false);
            };
        };
        getProductCategories();
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    return <LoadingHandlerScreen isLoading={isLoading} component={
        <>
            <MetaTags>
                <title>Detalhes de Produto | Grupo Terra</title>
            </MetaTags>

            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs={12} sm={6} md={5} className="d-flex justify-content-center align-items-center">
                                    <ProductImages images={formData.images} />
                                </Col>
                                <Col xs={12} sm={6} md={7}>
                                    <div className="mt-5 mt-lg-4">
                                        <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informações Principais</h5>

                                        <div className="row mb-4">
                                            <label htmlFor="fantasy_name" className="col-sm-2 col-form-label">Nome de Produto</label>
                                            <div className="col-sm-10">
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    id="product_name"
                                                    name="product_name"
                                                    value={formData.product_name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="corporate_reason" className="col-sm-2 col-form-label">ID Terra</label>
                                            <div className="col-sm-4">
                                                <ReactInputMask
                                                    mask='999.999.999'
                                                    type="text"
                                                    className="form-control"
                                                    id="product_id"
                                                    name="product_id"
                                                    value={formData.product_id}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                            </div>
                                            <label htmlFor="corporate_email" className="col-sm-2 col-form-label">Preço</label>
                                            <div className="col-sm-4">
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    id="corporate_email"
                                                    name="price"
                                                    value={formData.price}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="corporate_reason" className="col-sm-2 col-form-label">Categoria</label>
                                            <div className="col-sm-4">
                                                <select
                                                    type="text"
                                                    className="form-control"
                                                    id="corporate_reason"
                                                    name="category"
                                                    value={formData.category}
                                                    onChange={handleChange}
                                                    disabled
                                                >
                                                    {
                                                        categories?.map(obj => (
                                                            <option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            <label htmlFor="corporate_email" className="col-sm-2 col-form-label">NCM</label>
                                            <div className="col-sm-4">
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    id="corporate_email"
                                                    name="ncm"
                                                    value={formData.ncm}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4 d-flex align-items-center">
                                        <label htmlFor="isBaseImageVisible" className="col-sm-2 col-form-label">Imagem ERP habilitada</label>
                                        <div className="col-sm-4">
                                            <input
                                                disabled
                                                type="checkbox"
                                                className="form-check-input"
                                                id="isBaseImageVisible"
                                                name="isBaseImageVisible"
                                                checked={formData.isBaseImageVisible}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="mb-3">
                                            <label htmlFor="subject">Descrição do Produto</label>
                                            <textarea
                                                disabled
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                rows="8"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    } />
}

export default connect(null, { setBreadcrumbItems })(ProductDetails);
