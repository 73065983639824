import React, { useEffect, useRef, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, CardFooter, CardImg, CardTitle, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";
import { get, post } from "helpers/api_helper";
import ReactInputMask from "react-input-mask";
import toast from "react-hot-toast";
import hasImage from "common/hasImage";

const ProductEdit = (props) => {

    const { id } = useParams();

    const navigate = useNavigate();

    const { userData: { id_admin } } = useAuth();

    const inputRef = useRef(null);

    const [formData, setFormData] = useState({});
    const [categories, setCategories] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Clientes", link: "/clientes" },
        { title: "Edição de Produto", link: "#" },
    ];

    const getProductCategories = async () => {

        const response = await get('product_categories.php');

        if (response.success) {
            setCategories(response.categories);
        };
    };

    const handleSubmit = () => {

        const requiredFields = ["product_name", "product_id", "price", "category", "ncm", "description"];

        const isAnyFieldEmpty = requiredFields.some(field => !formData[field]);

        if (isAnyFieldEmpty) {
            toast.error('Por favor, preencha todos os campos obrigatórios.');
            return;
        };

        const productImages = formData.images.filter(obj => obj.type == 0);

        const payload = {
            product_id: id,
            name: formData.product_name,
            terra_id: formData.product_id,
            price: formData.price,
            category: formData.category,
            ncm: formData.ncm,
            description: formData.description,
            isBaseImageVisible: formData.isBaseImageVisible ? 1 : 0,
            images: productImages
        };

        post('products_edit.php', payload)
            .then(res => {
                if (res.success) {
                    toast.success('Produto editado com sucesso!')
                    navigate(`/produto-detalhes/${id}`);
                } else {
                    toast.error('Houve um erro ao editar este produto!')
                };
            })
            .catch(err => {
                console.error(err);
                toast.error('Erro de conexão! Tente novamente mais tarde.')
            });
    };

    useEffect(() => {

        props.setBreadcrumbItems('Edição de Produto', breadcrumbItems);

        const fetchData = async () => {
            const payload = { id_admin_user: id_admin, product_id: id };
            const response = await post('product_details.php', payload);

            if (response.success) {

                const { product } = response;

                setFormData(product);
                setIsLoading(false);
            };
        };

        getProductCategories();
        fetchData();

    }, []);

    const handleChangeImage = (image, index) => {

        const reader = new FileReader();
        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const aspectRatio = img.width / img.height;
                let newWidth, newHeight;

                // Definir dimensões de corte mantendo a proporção 1:1 (500x500)
                if (aspectRatio > 1) {
                    newWidth = 500;
                    newHeight = 500 / aspectRatio;
                } else {
                    newWidth = 500 * aspectRatio;
                    newHeight = 500;
                };

                // Centralizar a imagem no canvas
                const offsetX = (500 - newWidth) / 2;
                const offsetY = (500 - newHeight) / 2;

                // Desenhar a imagem cortada no canvas
                canvas.width = 500;
                canvas.height = 500;
                ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);

                // Converter o canvas de volta para o formato base64
                const croppedImage = canvas.toDataURL('image/jpeg');

                var productImages = formData.images;

                productImages[index].image = croppedImage;

                setFormData((prevData) => ({
                    ...prevData,
                    images: productImages
                }));
            };
        };
        reader.readAsDataURL(image);

    };

    const handleInsertImage = (image) => {

        const reader = new FileReader();
        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const aspectRatio = img.width / img.height;
                let newWidth, newHeight;

                // Definir dimensões de corte mantendo a proporção 1:1 (500x500)
                if (aspectRatio > 1) {
                    newWidth = 500;
                    newHeight = 500 / aspectRatio;
                } else {
                    newWidth = 500 * aspectRatio;
                    newHeight = 500;
                }

                const offsetX = (500 - newWidth) / 2;
                const offsetY = (500 - newHeight) / 2;

                // Desenhar a imagem cortada no canvas
                canvas.width = 500;
                canvas.height = 500;
                ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);

                const croppedImage = canvas.toDataURL('image/jpeg');

                const imgObject = {
                    type: '0',
                    image: croppedImage,
                    id_image: null
                };

                setFormData((prevData) => ({
                    ...prevData,
                    images: [...(prevData.images || []), imgObject],
                }));
            };
        };
        reader.readAsDataURL(image);
    };

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;

        const updatedValue = type === "checkbox" ? checked : value;

        setFormData((prevData) => ({ ...prevData, [name]: updatedValue }));
    };

    const handleRemoveImage = (product_id, image_id, index) => () => {

        function removeImage() {
            const filteredImages = formData.images.filter((_, i) => index !== i);

            setFormData((prevData) => ({
                ...prevData,
                images: filteredImages,
            }));
        }

        if (image_id) {
            post('/product_image_delete.php', { product_id, image_id })
        };

        removeImage();
    };

    const handleClickImage = () => {
        inputRef.current.click();
    };

    return <LoadingHandlerScreen isLoading={isLoading} component={
        <>
            <MetaTags>
                <title>Edição de Produto | Grupo Terra</title>
            </MetaTags>

            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Row xs={12}>
                                    {
                                        formData?.images?.map((item, index) => {

                                            return item.type === 1 ? (
                                                <Col key={index} xs={12} sm={4} md={3}>
                                                    <Card className="h-100">
                                                        <CardBody>
                                                            <CardTitle>
                                                                Imagem ERP
                                                            </CardTitle>
                                                            <img src={hasImage(item.image)} className="d-block" alt={'Imagem de Produto'} />
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                                :
                                                (
                                                    <Col key={index} xs={12} sm={4} md={3}>
                                                        <Card className="h-100">
                                                            <CardBody>
                                                                <CardTitle>
                                                                    Imagem do Portal
                                                                </CardTitle>
                                                                <CardImg src={hasImage(item.image)} className="d-block img-fluid" alt={'Imagem de Produto'} />
                                                            </CardBody>
                                                            <CardFooter className="d-flex justify-content-center align-items-center flex-row">
                                                                <input
                                                                    ref={inputRef}
                                                                    type="file"
                                                                    id="changeImage"
                                                                    accept=".jpg, .svg"
                                                                    style={{ display: "none" }}
                                                                    onChange={(e) => handleChangeImage(e.target.files[0], index)}
                                                                />
                                                                <Button style={{ marginRight: '1rem' }} onClick={handleClickImage}>
                                                                    <i className="mdi mdi-pencil"></i>
                                                                </Button>
                                                                <Button color="danger" onClick={handleRemoveImage(id, item.image_id, index)}>
                                                                    <i className="mdi mdi-trash-can"></i>
                                                                </Button>
                                                            </CardFooter>
                                                        </Card>
                                                    </Col>
                                                )
                                        })
                                    }
                                    {
                                        formData.images?.length < 4 && (
                                            <Col xs={12} sm={4} md={3}>
                                                <Card className="h-100" >
                                                    <label htmlFor="imageInput" className="h-100 d-flex justify-content-center align-items-center flex-column" style={{ cursor: 'pointer' }}>
                                                        <input
                                                            type="file"
                                                            id="imageInput"
                                                            accept=".jpg, .svg"
                                                            style={{ display: "none" }}
                                                            onChange={(e) => handleInsertImage(e.target.files[0])}
                                                        />
                                                        <i style={{ fontSize: '70px' }} className="mdi mdi-image-plus"></i>
                                                        Adicionar imagem
                                                    </label>
                                                </Card>
                                            </Col>
                                        )
                                    }

                                </Row>
                                <Col xs={12}>
                                    <div className="mt-5 mt-lg-4">
                                        <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Informações Principais</h5>

                                        <div className="row mb-4">
                                            <label htmlFor="fantasy_name" className="col-sm-2 col-form-label">Nome de Produto</label>
                                            <div className="col-sm-10">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="product_name"
                                                    name="product_name"
                                                    value={formData.product_name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="corporate_reason" className="col-sm-2 col-form-label">ID Terra</label>
                                            <div className="col-sm-4">
                                                <ReactInputMask
                                                    mask='999.999.999'
                                                    type="text"
                                                    className="form-control"
                                                    id="product_id"
                                                    name="product_id"
                                                    value={formData.product_id}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <label htmlFor="corporate_email" className="col-sm-2 col-form-label">Preço</label>
                                            <div className="col-sm-4">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="corporate_email"
                                                    name="price"
                                                    value={formData.price}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <label htmlFor="corporate_reason" className="col-sm-2 col-form-label">
                                                Categoria
                                            </label>
                                            <div className="col-sm-4">
                                                <select
                                                    type="text"
                                                    className="form-control"
                                                    id="corporate_reason"
                                                    name="category"
                                                    value={formData.category}
                                                    onChange={handleChange}
                                                >
                                                    {
                                                        categories?.map(obj => (
                                                            <option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            <label htmlFor="corporate_email" className="col-sm-2 col-form-label">NCM</label>
                                            <div className="col-sm-4">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="corporate_email"
                                                    name="ncm"
                                                    value={formData.ncm}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-4 d-flex align-items-center">
                                            <label htmlFor="isBaseImageVisible" className="col-sm-2 col-form-label">Imagem ERP habilitada</label>
                                            <div className="col-sm-4">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="isBaseImageVisible"
                                                    name="isBaseImageVisible"
                                                    checked={formData.isBaseImageVisible}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="mb-3">
                                            <label htmlFor="subject">Descrição do Produto</label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                rows="8"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="float-end">
                                <Button color="primary" onClick={handleSubmit}>
                                    <i className="mdi mdi-pencil"></i>
                                    Atualizar produto
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    } />
}
export default connect(null, { setBreadcrumbItems })(ProductEdit);
